<template>
  <div @click="$emit('close')" class="popin u-row u-center u-middle u-pad-y-xl u-z-front">
    <div class="u-z-back u-left" :class="[big ? 'u-w10of12' : 'u-w4of12 u-w8of12--sm', innerClass]" @click.stop="()=>{}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    big: {
      type: Boolean,
      default: false
    },
    innerClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.popin
  position fixed
  height 100vh
  width 100%
  top 0
  left 0

  // &:before
  //   content ''
  //   position absolute
  //   top 0
  //   left 0
  //   height 100%
  //   width 100%
  //   opacity .5
  //   background-color: $colors.red
  
  &__item
    max-height 100%
</style>


