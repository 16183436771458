import Apollo from '@/store/interfaces/Apollo'
import gql from 'graphql-tag'
import { storage } from '@/utils/storage'

import { keypoint, endpage, homepage, basePage, config, categories, answerCategories, view } from './parts'

const apollo = new Apollo(process.env.VUE_APP_CRAFT_API, false)
apollo.setToken('Bearer ' + process.env.VUE_APP_CRAFT_TOKEN)
const graphqlClient = apollo.client

const site = process.env.VUE_APP_CRAFT_WEBSITE

class APICraft {

  constructor () {
    this.local = {
      website: process.env.VUE_APP_CRAFT_WEBSITE_FR,
      lang: 'fr'
    }
  }

  set local (local) {
    this._website = local.website
    this._lang = local.lang
  }

  get website () {
    return this._website
  }

  get lang () {
    return this._lang
  }

  getHomepage () {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchHomepage {
            entry (site: "${this.website}", type: "sthomepage") {
              ... on sthomepage_sthomepage_Entry {
                ${homepage}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem(`fetchHomepage_${this.lang}`, result)
      })
      .catch(err => {
        console.error('Error catching homepage ', err)
      })
    })
    const cache = storage.getItem(`fetchHomepage_${this.lang}`)

    return {
      request,
      cache
    }
  }

  getEndpage () {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEndpage {
            entry (site: "${this.website}", type: "stendpage") {
              ... on stendpage_stendpage_Entry {
                ${endpage}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem(`fetchEndpage_${this.lang}`, result)
      })
      .catch(err => {
        console.error('Error catching endpage ', err)
      })
    })
    const cache = storage.getItem(`fetchEndpage_${this.lang}`)

    return {
      request,
      cache
    }
  }

  getPage (page) {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchPage {
            entry (site: "${this.website}", type: "${page.type}") {
              ... on ${page.model} {
                ${basePage}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem(`fetchPage_${this.lang}_${page.name}`, result)
      })
      .catch(err => {
        console.error(`Error catching page ${page.name} `, err)
      })
    })
    const cache = storage.getItem(`fetchPage_${this.lang}_${page.name}`)

    return {
      request,
      cache
    }
  }

  getConfig () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchConfig {
            entry (site: "${this.website}", type: "stConfg") {
              ... on stConfg_stConfg_Entry {
                ${config}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem(`fetchConfig_${this.lang}`, result)
      })
      .catch(err => {
        console.error('Error catching config ', err)
      })
    })
    const cache = storage.getItem(`fetchConfig_${this.lang}`)

    return {
      request,
      cache
    }
  }

  getEntries () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEntries {
            keypoints: entries (site: "${this.website}", type: "stKeypoint", level: 1) {
              ... on stKeypoint_stKeypoint_Entry {
                ${keypoint}
                keypoints: children {
                  ... on stKeypoint_stKeypoint_Entry {
                    ${keypoint}
                  }
                }
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.keypoints

        resolve(result)
        storage.setItem( `fetchEntries_${this.lang}`, result)
      })
      .catch(err => {
        console.error('Error catching entries ', err)
      })
    })
    const cache = storage.getItem(`fetchEntries_${this.lang}`)

    return {
      request,
      cache
    }
  }

  getViews () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchViews {
            views: entries (site: "${this.website}", type: "dacia360") {
              ... on dacia360_dacia360_Entry {
                ${view}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.views

        resolve(result)
        storage.setItem( `fetchViews_${this.lang}`, result)
      })
      .catch(err => {
        console.error('Error catching entries ', err)
      })
    })
    const cache = storage.getItem(`fetchViews_${this.lang}`)

    return {
      request,
      cache
    }
  }

  getEntriesFiltered (categories = []) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEntries ($categories: [QueryArgument]) {
            keypoints: entries (site: "${this.website}", type: "stKeypoint", stCategories: $categories) {
              ... on stKeypoint_stKeypoint_Entry {
                id
              }
            }
          }
        `,
        variables: { categories: categories }
      })
      .then(res => {
        const result = res.data.keypoints

        resolve(result)
        storage.setItem( `fetchEntries_${this.lang}_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching entries ', err)
      })
    })
    const cache = storage.getItem(`fetchEntries_${this.lang}_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getCategories () {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchCategories {
            categories (site: "${this.website}", group: "stCategories") {
              ... on stCategories_Category {
                ${categories}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.categories

        resolve(result)
        storage.setItem(`fetchCategories_${this.lang}`, result)
      })
      .catch(err => {
        console.error('Error catching categories ', err)
      })
    })
    const cache = storage.getItem(`fetchCategories_${this.lang}`)

    return {
      request,
      cache
    }
  }

  getAnswerCategories () {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchCategories {
            categories (site: "${this.website}", group: "stAnswerCategory") {
              ... on stAnswerCategory_Category {
                ${answerCategories}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.categories

        resolve(result)
        storage.setItem(`fetchAnswerCategories_${this.lang}`, result)
      })
      .catch(err => {
        console.error('Error catching categories ', err)
      })
    })
    const cache = storage.getItem(`fetchAnswerCategories_${this.lang}`)

    return {
      request,
      cache
    }
  }

  getCategoryCount (category = []) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchCategoryCount ($category: [QueryArgument]) {
            entryCount (site: "${this.website}", type: "stKeypoint", stCategories: $category)
          }
        `,
        variables: { category: category }
      })
      .then(res => {
        const result = res.data.entryCount

        resolve(result)
        storage.setItem( `fetchCategoryCount_${this.lang}_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching entries ', err)
      })
    })
    const cache = storage.getItem(`fetchCategoryCount_${this.lang}_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  imgSize ({id, width, height}) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      let query = {}

      if (width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int, $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true, height: $height)
                }
              }
            }
          `,
          variables: { id, width, height }
        }
      }
      else if (width && !height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true)
                }
              }
            }
          `,
          variables: { id, width }
        }
      }
      else if (!width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(height: $height, immediately: true)
                }
              }
            }
          `,
          variables: { id, height }
        }
      }
      else {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument])  {
              asset(id: $id) {
                ... on default_Asset {
                  url
                }
              }
            }
          `,
          variables: { id }
        }
      }

      graphqlClient.query(query)
        .then(res => {
          resolve(res.data.asset.url)

          const result = res.data.asset.url

          resolve(result)
          storage.setItem( `fetchImg_${JSON.stringify(args)}`, result)
        })
        .catch(err => {
          console.error('Error catching global params ', err)
        })
    })

    const cache = storage.getItem(`fetchImg_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }
}

export const api = new APICraft()