<template>
  <div
    :style="{height: height + 'px'}"
    class="footer u-middle u-z-back u-between u-middle u-row u-bg-black u-white u-pad-x-lg--lg u-pad-x-xl"
  >
    <div class="u-row u-left u-middle">
      <template v-for="page in pages">
        <div
          v-if="!page.url"
          @click="$emit('page', page.name)"
          class="footer__link u-uppercase t-bold u-cursor-pointer u-marg-r-xs--lg u-marg-r-sm"
        >
           {{ $t('pages.' + page.name) }}
        </div>
        <a
          v-else
          class="footer__link u-uppercase t-bold u-cursor-pointer u-marg-r-xs--lg u-marg-r-sm u-block"
          :href="page.url"
          target="_blank"
        >
          {{ $t('pages.' + page.name) }}
        </a>
      </template>
    </div>
    <!-- <sound class="footer__sound" /> -->
    <div class="u-row u-middle">
      <div class="t-text t-bold u-marg-r-xs">
        {{ $t('share') }}
      </div>
      <socials class="footer__link" color="white" />
    </div>
  </div>
</template>

<script>
import Socials from '@/components/common/Socials'
import Sound from '@/components/common/Sound'
import AppButtonRound from '@/components/common/AppButtonRound'
import AppPopin from '@/components/common/AppPopin'
import Categories from '@/components/Categories'

export default {
  computed: {
    pages () {
      return this.$store.getters['pages/annexes']
    }
  },

  props: {
    height: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      isFilter: false
    }
  },

  components: {
    Socials,
    AppPopin,
    AppButtonRound,
    Categories,
    Sound
  },

  watch: {
    $route () {
      this.isFilter = false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/mixins/viewport-sizer'

.footer
  position absolute
  bottom: 0
  left: 0
  right: 0
  
  &__link
    font-family dacia
    font-weight $weights.bold
    viewportSizer('font-size', 16, 1920)

  &__share
    viewportSizer('font-size', 16, 1920)

  &__sound
    width 20px

</style>
