<template>
  <div>
    <component
      :is="to.name ? 'router-link' : 'div'"
      :to="to"
      class="keypoint-q u-cursor-pointer"
      draggable="false"
      @click.stop="$emit('click')"
    >
      <img draggable="false" class="keypoint-q__main" src="/images/keypointQuestion--2.png" />
    </component>
  </div>
</template>

<script>
import Object3d from '@/plugins/Dom3d/mixin'
import { webGL } from '@/webGL/WebGL'

const width = 50

export default {
  mixins: [ Object3d ],

  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: .5
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  },

  computed: {
    sizeString () {
      let _width = this.$device.isMobile ? width : width * 10
      return String(Math.round(this.size * _width)) + 'px'
    },
    border () {
      return String(this.$device.isMobile ? 2 : 20) + 'px'
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

// width = 30px
width = v-bind(sizeString)
borderWidth = v-bind(border)

.keypoint-q
  display block
  opacity 1
  width width
  height width
  transition opacity .3s linear
  
  &:before
    content ''
    position absolute
    top -200%
    left -200%
    height 500%
    width 500%
    pointer-events none
    border: solid borderWidth $colors.red
    animation 2s ease-in-out-quad infinite pulse

  &__main
    position absolute
    top 0
    left 0
    height 100%
    width 100%
    display block
    position relative

  &.is-complete &__item
    &__main
      background-color:  $colors.green
    &__heart
      background-color: $colors.white


@keyframes pulse
  0%
    transform: translateZ(0) scale(.2)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0
</style>