<template>
  <div class="u-pad-sm u-wrapper-vpanel u-bg-white u-middle u-flex u-column u-between u-z-middle">
    <div class="u-full-width">
      <template v-for="page in pages">
        <div
          v-if="!page.url"
          role="link"
          class="u-marg-b-md u-black t-h4 t-dacia u-cursor-pointer u-uppercase"
          @click="$emit('page', page.name)"
        >
          {{ $t('pages.' + page.name) }}
        </div>
        <a
          v-else
          class="u-marg-b-md u-black t-h4 t-dacia u-cursor-pointer u-uppercase u-block"
          :href="page.url"
          target="_blank"
        >
          {{ $t('pages.' + page.name) }}
        </a>
      </template>
    </div>
    <div class="u-full-width">
      <div class="u-back t-h4 u-marg-b-sm">
        {{ $t('share') }}
      </div>
      <socials display="between" />
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'
import Socials from '@/components/common/Socials'

export default {
  components: {
    AppButtonRound,
    Socials
  },

  computed: {
    pages () {
      return this.$store.getters['pages/annexes']
    }
  },

  watch: {
    $route () {
      this.$emit('close')
    }
  },

  mounted () {
    this.$ga.send('navigation', 'about', 'open')
  },

  beforeUnmount () {
    this.$ga.send('navigation', 'about', 'close')
  }
}
</script>

<style lang="stylus">
  
</style>