import { api } from '@/store/interfaces/apiCraft'

import { nbrPass, nbrPassInsitu } from '@/assets/data'

import { storage } from '@/utils/storage'

let totalQuestions = 0
let seenItems = []

export default {
  namespaced: true,

  state: {
    isInSitu: false,
    keypoints: [],
    views: [],
    currentQuestionIndex: -1,
    answerCategories: [],
    categories: [],
    nbrPass: 0,
    loaded: false
  },

  getters: {
    keypoints: state => {
      return state.keypoints
    },
    keypoint: state => (arg1, arg2) => {
      const keypoint = state.keypoints.find(keypoint => keypoint.slug === arg1)

      if (keypoint && arg2) {
        return keypoint.keypoints.find(keypoint => keypoint.slug === arg2)
      }

      return keypoint
    },
    questions: state => {
      return state.keypoints.filter(keypoint => keypoint.questions.length)
    },
    views: state => {
      return state.views
    },
    view: state => arg => {
      return state.views.find(view => view.slug === arg)
    },
    nextKeypoint: (state, getters) => (arg1, arg2) => {
      const keypoint = getters.keypoint(arg1)

      if (!arg2) {
        return state.keypoints[keypoint.index + 1] ? state.keypoints[keypoint.index + 1] : state.keypoints[0]
      }
      else {
        const subKeypoint = getters.keypoint(arg1, arg2)
        return keypoint.children[subKeypoint.index + 1] ? keypoint.children[subKeypoint.index + 1] : keypoint.children[0]
      }
    },
    prevKeypoint: (state, getters) => (arg1, arg2) => {
      const keypoint = getters.keypoint(arg1)

      if (!arg2) {
        return keypoint.index - 1 >= 0 ? state.keypoints[keypoint.index - 1] : state.keypoints[state.keypoints.length - 1]
      }
      else {
        const subKeypoint = getters.keypoint(arg1, arg2)
        return keypoint.children[subKeypoint.index + 1] ? keypoint.children[subKeypoint.index + 1] : keypoint.children[0]
      }
    },
    stupidNextKeypoint: (state, getters) => (arg1, arg2) => {
      const keypoint = getters.keypoint(arg1, arg2)
      return state.keypoints[keypoint.index + 1]
    },
    stupidPrevKeypoint: (state, getters) => (arg1, arg2) => {
      const keypoint = getters.keypoint(arg1, arg2)
      return state.keypoints[keypoint.index - 1]
    },
    categories: state => {
      return state.categories
    },
    totalKeypoints: state => {
      let total = 0
      state.keypoints.forEach(k => {
        total += k.keypoints.length ? k.keypoints.length : 1
      })

      return total
    },
    totalCompleteKeypoints: state => {
      let total = 0
      state.keypoints.forEach(k => {
        let completeChild = 0
        k.keypoints.forEach(key => {
          completeChild += key.isComplete ? 1 : 0
        })
        total += completeChild !== 0 ? completeChild : k.keypoints.isComplete ? 1 : 0
      })

      return total
    },
    totalQuestions: state  => {
      return state.totalQuestions
    },
    answerCategories: state => {
      return state.answerCategories
    },
    currentQuestionIndex: state => {
      return state.currentQuestionIndex
    },
    currentQuestion: (state, getters) => {
      return getters.questions[state.currentQuestionIndex]
    },
    questionAnswered: (state, getters) => {
      return getters.questions.filter(question => question.isComplete).length
    },
    passPossible: (state, getters) => {
      if (state.isInSitu) {
        return state.nbrPass < nbrPassInsitu
      }
      return state.nbrPass < nbrPass
    },
    loaded: state => {
      return state.loaded
    }
  },

  actions: {
    seenItems ({ commit, state }) {
      return new Promise(resolve => {
        storage.getItem('seenItems').then(res => {
          if (res !== null) {
            seenItems = res
          }
          resolve()
        })
      })
    },

    keypoints ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getEntries()
        // cache.then(res => {
        //   if (res !== null) {
        //     commit('keypoints', formatKeypoints(res))
        //     commit('loaded', true)
        //     resolve()
        //   }
        // })

        request.then(res => {
          commit('keypoints', formatKeypoints(res))
          commit('totalQuestions', totalQuestions)
          commit('loaded', true)
          resolve()
        })
      })
    },

    views ({ commit, getters, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getViews()
        // cache.then(res => {
        //   if (res !== null) {
        //     commit('views', res)
        //   }
        // })

        request.then(res => {
          res.forEach(view => {
            view.position[0].x = -view.position[0].x
            view.customNeighbors.forEach(neighbor => {
              neighbor.position[0].x = -neighbor.position[0].x
              return neighbor
            })
            view.customKeypoints = view.customKeypoints.map(keypoint => {
              return getters.keypoint(keypoint.slug)
            })
          })
          commit('views', res)
          commit('loaded', true)
          resolve()
        })
      })
    },

    categories ({ commit, state, dispatch }) {
      return new Promise(resolve => {
        const { request, cache } = api.getCategories()
        cache.then(res => {
          // if (res !== null) {
          //   commit('categories', res)
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          dispatch('catCount', res)
          resolve()
        })
      })
    },

    answerCategories ({ commit, state, dispatch }) {
      return new Promise(resolve => {
        const { request, cache } = api.getAnswerCategories()
        cache.then(res => {
          // if (res !== null) {
          //   commit('categories', res)
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          commit('answerCategories', res)
          resolve()
        })
      })
    },
    currentQuestionIndexNext ({ commit, state, getters }) {
      const questions = getters.questions
      for(let i = 0; i < questions.length; i++) {
        const nextIndex = (i + state.currentQuestionIndex) % questions.length

        if (questions[nextIndex] && !questions[nextIndex].isComplete) {
          commit('currentQuestionIndex', nextIndex)
          return
        }
      }
    },
    reinit () {
      storage.removeItem('seenItems')
      seenItems = []
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    keypoints (state, payload) {
      state.keypoints = payload
    },
    views (state, payload) {
      state.views = payload
    },
    filter (state, payload) {
      const entries = payload.map(cat => cat.id)
      state.keypoints.forEach((keypoint, i) => {
        keypoint.keypoints.forEach((key, j) => {
          key.isActive = entries.includes(key.id)
        })
        keypoint.isActive = entries.includes(keypoint.id)
      })
    },
    categories (state, payload) {
      state.categories = payload
    },
    totalQuestions (state, payload) {
      state.totalQuestions = payload
    },
    answerCategories (state, payload) {
      state.answerCategories = payload
    },
    currentQuestionIndex (state, payload) {
      state.currentQuestionIndex = payload
    },
    passQuestion (state) {
      state.nbrPass++
    },
    isInSitu (state) {
      state.isInSitu = true
    },
    async completed (state, args) {
      if (args.question !== undefined) {
        args.keypoint.questions[args.question].isComplete = true
      }
      else {
        args.keypoint.isComplete = true
        addSeen(args.keypoint.slug)
      }
    }
  }
}

function addSeen (slug) {
  if (!seenItems.includes(slug)) {
    seenItems.push(slug)
    storage.setItem('seenItems', seenItems)
  }
}

// We can do recursive stuff
// but for functionnal issues
// is is way more evolutive
// and easy no to
function formatKeypoints (keypoints) {
  let globalEpisodeIndex = 0
  let globalQuestionIndex = 0
  keypoints.forEach((keypoint, i) => {
    keypoint.keypoints.forEach((key, j) => {
      globalQuestionIndex = formatQuestions(key.questions, globalQuestionIndex)
      key.globalIndex = globalEpisodeIndex
      key.isComplete = seenItems.includes(key.slug)
      key.isActive = true
      key.position[0].x = -key.position[0].x
      globalEpisodeIndex++
    })
    globalQuestionIndex = formatQuestions(keypoint.questions, globalQuestionIndex)

    keypoint.position[0].x = -keypoint.position[0].x
    keypoint.index = i
    keypoint.isComplete = seenItems.includes(keypoint.slug)
    keypoint.isActive = true
  })

  return keypoints
}

function formatQuestions (questions, globalIndex) {
  questions.forEach((question, k) => {
    question.answers.forEach((answer, l) => {
      answer.index = l
    })
    question.index = k
    question.globalIndex = globalIndex
    question.isComplete = seenItems.includes(question.slug)
    question.catChosen = []
    globalIndex++
  })

  totalQuestions = globalIndex > totalQuestions ? globalIndex : totalQuestions

  return globalIndex
}