<template>
  <app-popin @close="$emit('next')">
    <div class="u-radius u-bg-green">
      <div class="u-center">
        <div v-if="img" class="popin__picto u-inline-block u-round u-bg-red u-pad-sm">
          <!-- <svg class="u-fill-green u-full-width u-full-height u-pad-sm" preserveAspectRatio="xMidYMid meet">
            <use :xlink:href="'#' + svg"/>
          </svg> -->
          <img :src="img" class="u-full-width u-full-height u-fit-contain">
        </div>
      </div>
      <div class="u-pad-md">
        <div class="t-h3 t-bold u-red u-center">
          {{ text }}
        </div>
        <div class="u-center u-marg-t-md">
          <app-button :second="true" @click="$emit('next')">
            {{ cta }}
          </app-button>
        </div>
      </div>
    </div>
  </app-popin>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'

export default {
  components: {
    AppPopin
  },

  props: {
    // svg: {
    //   type: String,
    //   default: 'hand'
    // },
    img: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    cta: {
      type: String,
      default: 'J’ai compris 🌞'
    }
  }
}
</script>

<style lang="stylus">
.popin
  &__picto
    width 90px
    height 90px
    margin-top -45px
    
</style>