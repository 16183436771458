import {
  WebGLRenderer,
  sRGBEncoding,
  PCFSoftShadowMap,
  ReinhardToneMapping,
  ACESFilmicToneMapping,
  WebGLRenderTarget,
  WebGLMultisampleRenderTarget,
  LinearFilter,
  RGBAFormat
} from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'

import { postProcess as dataPostProcess } from '@/assets/data'

import { levelFPS } from '@/utils/LevelFPS'

const apertureFactor = .00001

export class Renderer {
  constructor(scene, camera) {
    this.scene = scene
    this.camera = camera

    this.resize = this.resize.bind(this)
    this.update = this.update.bind(this)
    this.matChanger = this.matChanger.bind(this)
    this.usePostprocess = this.usePostprocess.bind(this)
    this.renderQuality = this.renderQuality.bind(this)

    this.useShadow = true

    this.setInstance() 
    this.usePostprocess()
    this.setPostProcess()
  }

  renderQuality () {
    this.usePostprocess()
    this.renderer.shadowMap.enabled = this.useShadow && !levelFPS.levelProperty.includes('NO_SHADOW')
  }

  usePostprocess () {
    this.isPostprocess = (dataPostProcess.useBokeh || dataPostProcess.useBloom) && !levelFPS.levelProperty.includes('NO_POSTPROCESS')
    if (!this.isPostprocess) {
      this.renderer.toneMappingExposure = 1.3
    }
    else if (dataPostProcess.useBokeh) {
      this.renderer.toneMappingExposure = 2.5
    }
    else if (dataPostProcess.useBloom) {
      this.renderer.toneMappingExposure = .5
    }
  }

  setInstance () {
    this.clearColor = '#646B52'

    // Renderer
    this.renderer = new WebGLRenderer({
      alpha: false,
      antialias: true,
      stencil: false,
      preserveDrawingBuffer: false,
      powerPreference: 'high-performance',
    })
    this.renderer.setClearColor(this.clearColor)

    this.context = this.renderer.getContext()

    // Add stats panel
    if (this.stats) {
      this.stats.setRenderPanel(this.context)
    }
  }

  setPostProcess () {
    this.postProcess = {}

    /**
     * Render pass
     */
    this.postProcess.renderPass = new RenderPass(this.scene, this.camera)

    /**
     * Bokeh Pass
     */
    this.postProcess.bokehPass = new BokehPass(this.scene, this.camera, {
      focus: 9,
      aperture: dataPostProcess.apertureMax * apertureFactor,
      maxblur: 50
    })

    /**
     * BloomPass Pass
     * Resolution
     * strength
     * radius
     * threshold
     */
    this.postProcess.bloomPass = new UnrealBloomPass({x: 256, y: 256}, 1, 0, 0)

    /**
     * Effect composer
     */
    const RenderTargetClass = window.devicePixelRatio >= 2 ? WebGLRenderTarget : WebGLMultisampleRenderTarget
    // const RenderTargetClass = WebGLRenderTarget
    this.renderTarget = new RenderTargetClass( window.innerWidth, window.innerHeight, {
        generateMipmaps: false,
        minFilter: LinearFilter,
        magFilter: LinearFilter,
        format: RGBAFormat,
        encoding: sRGBEncoding,
      }
    )
    this.postProcess.composer = new EffectComposer(this.renderer, this.renderTarget)
    this.postProcess.composer.setSize(window.innerWidth, window.innerHeight)
    this.postProcess.composer.setPixelRatio(window.devicePixelRatio)

    this.postProcess.composer.addPass(this.postProcess.renderPass)

    if (dataPostProcess.useBokeh) {
      this.postProcess.composer.addPass(this.postProcess.bokehPass)
    }
    if (dataPostProcess.useBloom) {
      this.postProcess.composer.addPass(this.postProcess.bloomPass)
    }

    this.addGui()
  }

  addGui () {
    // const folder = gui.addFolder('Bokeh')

    // gui.add( guiObj, 'focus', 0, 30.0, 1 ).onChange( this.matChanger )
    // gui.add( guiObj, 'aperture', 0, 100, 1 ).onChange( this.matChanger )
    // gui.add( guiObj, 'maxblur', 0.0, 100, 0.01 ).onChange( this.matChanger )
  }

  matChanger () {
    // this.postProcess.bokehPass.uniforms[ 'focus' ].value = guiObj.focus
    // this.postProcess.bokehPass.uniforms[ 'aperture' ].value = guiObj.aperture * 0.00001
    // this.postProcess.bokehPass.uniforms[ 'maxblur' ].value = guiObj.maxblur
  }

  resize ({width, height}) {
    const pixelRatio = levelFPS.levelProperty.includes('RESOLUTION_LOW') ? .5 : levelFPS.levelProperty.includes('NO_RETINA') ? 1 : window.devicePixelRatio

    // Instance
    this.renderer.setSize(width, height)
    this.renderTarget.setSize(width, height)
    this.renderer.setPixelRatio(pixelRatio)

    // Post process
    this.postProcess.composer.setSize(width, height)
    this.postProcess.composer.setPixelRatio(pixelRatio)

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  update () {
    // console.log(this.renderer)
    if (this.isPostprocess) {
      this.postProcess.composer.render()
    }
    else {
      this.renderer.render(this.scene, this.camera)
    }

    if (this.stats) {
      this.stats.afterRender()
    }
  }

  get canvas () {
    return this.renderer.domElement
  }

  set bokehDist (value) {
    this.postProcess.bokehPass.uniforms[ 'focus' ].value = value
  }

  get bokehDist () {
    return this.postProcess.bokehPass.uniforms[ 'focus' ].value
  }

  // bigger bokeh zone = more blur
  set bokehZone (value) {
    this.postProcess.bokehPass.uniforms[ 'aperture' ].value = value * dataPostProcess.apertureMax * apertureFactor
  }
  // smaller bokeh zone = less blur
  get bokehZone () {
    return this.postProcess.bokehPass.uniforms[ 'aperture' ].value / (dataPostProcess.apertureMax * apertureFactor)
  }

  destroy () {
    this.renderer.renderLists.dispose()
    this.renderer.dispose()
    this.renderTarget.dispose()
    this.postProcess.composer.renderTarget1.dispose()
    this.postProcess.composer.renderTarget2.dispose()
  }
}
