<template>
  <div :style="{top: `${top - 1}px`}" class="u-z-middle u-z-back--sm keypoint-question__wrapper u-wrapper-vpanel u-row u-bottom u-center">
    <app-images v-if="isInSitu" class="u-wrapper-panel u-fit-cover" :images="data.image" />
    <div class="keypoint-question u-z-back--md u-w3of12 u-w12of12--sm u-row u-middle u-top--sm" >
      <question @answer="onAnswer" @next="currentQuestion++" :data="data.questions[0]" :index="data.index" />
    </div>
  </div>
</template>

<script>
import Question from '@/components/Question'
import AppButton from '@/components/common/AppButton'

export default {
  props: {
    transition: {
      type: Boolean,
      default: true
    },
    isInSitu: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      currentQuestion: -1,
      isQuestion: false,
      top: 0
    }
  },

  computed: {
    data () {
      if (this.isInSitu) {
        return this.$store.getters['data/questions'][parseInt(this.$route.params.question)]
      }
      else {
        return this.$store.getters['data/keypoint'](this.$route.params.question)
      }
    },
    totalQuestions () {
      return this.$store.getters['data/totalQuestions']
    }
  },

  components: {
    Question
  },

  mounted () {
    this.onInit()
    this.$ga.send('navigation', 'Keypoint', 'quizz', this.data.slug)
    this.onResize()
  },

  methods: {
    onInit () {
      this.currentQuestion = 0
    },

    onResize () {
      const header = document.querySelector('.js-header-pano')
      this.top = this.$offset.absolute(header).top + header.offsetHeight
    },

    onAnswer ({ right, answers }) {
      if (!this.data.isCompleted) {
        if (right) {
          this.$store.commit('global/right')
        }
        else {
          this.$store.commit('global/wrong')
        }
      }

      this.$store.commit('data/completed', {
        keypoint: this.data,
        question: 0,
        answers: answers
      })
    },

    checkKeypointStatus () {
      if (!this.data.questions.filter(question => !question.isComplete).length) {
        this.$store.commit('data/completed', { keypoint: this.data })
      }
    }
  },

  watch: {
    currentQuestion () {
      if (this.currentQuestion >= this.data.questions.length) {
        this.$store.commit('data/completed', { keypoint: this.data })
        this.$store.dispatch('data/currentQuestionIndexNext')
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="stylus">
.keypoint-question
  position absolute
  left 0
  top 0
  height 100%

  &__wrapper
    bottom var(--footer-height)
</style>