<template>
  <!-- START DEBUGGER -->
  <template v-if="$route.query.debug">
    <helper-3d
      :position="pointerPos"
      :rotation="{
        x: debugRotation.x,
        y: debugRotation.y,
        z: debugRotation.z
      }"
    />
    <div class="debug-panel u-black u-pad-xs u-bg-white">
      <div><b>Use keyboards arrows and A or Z</b></div>
      <div><b>X</b> {{ debugRotation.x.toFixed(2) }}</div>
      <div><b>Y</b> {{ debugRotation.y.toFixed(2) }}</div>
      <div><b>Z</b> {{ debugRotation.z.toFixed(2) }}</div>
    </div>
  </template>
  <!-- END DEBUGGER -->
</template>

<script>
import Helper3d from '@/components/Helper3d'

export default {
  components: {
    Helper3d
  },

  data () {
    return {
      debugRotation: {x: 0, y:0, z: 0}
    }
  },

  props: {
    pointerPos: {
      type: Object,
      default: () => {return {x: 0, y:0, z:0}}
    }
  },

  methods: {
    onKeydown (e) {
      switch (e) {
        case 38:
          this.debugRotation.x -= Math.PI * .1
          break
        case 40:
          this.debugRotation.x += Math.PI * .1
          break
        case 37:
          this.debugRotation.y -= Math.PI * .1
          break
        case 39:
          this.debugRotation.y += Math.PI * .1
          break
        case 65:
          this.debugRotation.z += Math.PI * .1
          break
        case 90:
          this.debugRotation.z -= Math.PI * .1
          break
      }
    }
  }

}
</script>

<style lang="stylus">
.debug-panel
  position absolute
  bottom 0
  left 0
  z-index 100
</style>