<template>
  <div class="u-full-height u-full-width u-relative">
    <div
      class="panel u-cursor-default u-w3of12 u-w12of12--md"
      :class="{'u-overflow-y-scroll u-invisible-scroll': $device.isTablet}"
      ref="panel"
    >
      <!-- <transition name="t-translate--down--delayed">
      </transition> -->
      <app-article
        v-if="!isFull"
        @full="isFull = true"
        @close="$emit('close')"
        :isFull="false"
        :data="data"
        @click.stop="() => {}"
      />
      <div v-else class="panel__wrapper u-wrapper-panel">
        <div class="panel__wrapper__notch" />
        <app-article
          @close="$emit('close')"
          :isFull="true"
          :data="data"
          @click.stop="() => {}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'
import AppButton from '@/components/common/AppButton'

import AppArticle from '@/components/AppArticle'

import gsap from 'gsap'

export default {
  components: {
    AppArticle,
    AppButton,
    AppPopin
  },

  data () {
    return {
      isScolled: false,
      isFull: false
    }
  },

  props: {
    data : {
      type: Object,
      default: () => { return {} }
    },
    entry: {
      type: Object,
      default: () => { return {} }      
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

notchSize = 30px

.panel
  position absolute
  left 0
  top 0
  height 100%

  +mq($until: 'desktop')
    width 100%
    top 0
    bottom auto

  &__arrow
    height 60px
    width 60px
    position absolute
    top -30px
    left calc( 50% - 30px )
    padding 15px 15px 12px
    transform scale(1, -1)
    transition transform .5s ease-in-out-quart
    
    &.is-open
      transform scale(1, 1)

  &__button
    height 45px
    width 45px
    padding 15px
    border-radius 50%

  &__wrapper
    // BEFORE
    // +-------------+
    // |xxxxxxxxxxxxx|
    // |xxxxxxxxxxxxx|
    // |            /
    // +-----------/
    &:before
      content ''
      top 0
      left 0
      right 0
      bottom notchSize
      position absolute
      background-color: $colors.white
      transition background ease-in-out-quad .3s

      +mq($from: 'desktop')
        bottom 0
      
      
    // AFTER
    // +-------------+
    // |xxxxxxxxxxx  |
    // |xxxxxxxxxxx  |
    // |xxxxxxxxxxx /
    // +-----------/
    &:after
      content ''
      top 0
      left 0
      right notchSize
      bottom 0
      position absolute
      background-color: $colors.white
      transition background ease-in-out-quad .3s

    // NOTCH
    // +-------------+
    // |             |
    // |             |
    // |           xx
    // +-----------x
    &__notch
      position absolute
      bottom 0
      right 0
      height notchSize
      width notchSize
      border-top: notchSize solid $colors.white
      border-right notchSize solid transparent
      transition border ease-in-out-quad .3s
    
</style>