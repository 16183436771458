<template>
  <div class="u-wrapper-vpanel u-row">
    <div class="u-w3of12 u-w4of12--lg u-w12of12--md u-flex u-column u-full-height">
      <h2 class="tuto__header t-h1 u-pad-sm u-bg-white u-uppercase">
        <div v-if="$device.isDesktop" class="u-marg-b-xs">
          <div class="u-w4of12 u-box-16by9">
            <div class="u-wrapper-panel">
              <svg class="u-fill-black u-full-height u-full-width u-fit-contain">
                <use xlink:href="#dacia"/>
              </svg>
            </div>
          </div>
        </div>
        {{ tutorials[0].titre }}
      </h2>
      <div class="tuto__content u-bg-green u-white u-pad-sm u-overflow-y-scroll u-invisible-scroll u-relative">
        <div v-if="isInSitu" class="t-h4">
          {{ $t('tutos.subTitle') }}
        </div>
        <div class="t-h4 u-marg-y-sm">
          {{ $t('tutos.title1') }}
        </div>
        <div v-for="i in [1]" class="u-row u-top u-marg-b-sm">
          <div class="tuto__img u-box-1by1 u-w2of12">
            <div v-if="!isInSitu" class="u-wrapper-panel u-pad-xs u-pad-xxs--md">
              <app-images class="u-fit-contain u-block" :images="tutorials[i].image" />
            </div>
            <div v-else class="u-wrapper-panel">
              <img class="u-fit-cover u-block" src="/images/qrcode-tuto.png" >
            </div>
          </div>
          <div v-if="!isInSitu" class="u-w10of12 u-pad-l-sm">
            <div v-if="tutorials[i].titre !== ''" class="t-text">
              {{ tutorials[i].titre }}
            </div>
            <div v-if="tutorials[i].text !== ''" class="t-text t-bold" v-html="tutorials[i].text.replace('\\n', '<br />')" />
          </div>
          <div v-else class="u-w10of12 u-pad-l-sm">
            <div class="t-text">
              {{ $t('tutos.obj') }}
            </div>
          </div>
        </div>
        <div class="t-h4 u-marg-b-sm">
          {{ $t('tutos.title2') }}
        </div>
        <div v-for="i in [2, 3]" class="u-row u-top u-marg-b-xs">
          <div class="tuto__img u-box-1by1 u-w2of12">
            <div class="u-wrapper-panel u-pad-xs u-pad-xxs--md">
              <app-images class="u-fit-contain u-block" :images="tutorials[i].image" />
            </div>
          </div>
          <div class="u-w10of12 u-pad-l-sm">
            <div class="t-text t-bold">
              {{ tutorials[i].titre }}
            </div>
            <div v-if="tutorials[i].text !== ''" class="t-text">
              {{ tutorials[i].text }}
            </div>
          </div>
        </div>
        <app-button :to="firstViewParam" class="tuto__button u-full-width--md" :white="true">
          {{ hpData.CTALabel }}
        </app-button>
      </div>
    </div>
    <app-images class="u-fit-cover u-w9of12 u-w8of12--lg" :images="hpData.image" />
  </div>
</template>

<script>

export default {
  computed: {
    hpData () {
      return this.$store.getters['pages/homepage']
    },
    firstViewParam () {
      if (this.$store.getters['global/pageStart'].name) {
        return this.$store.getters['global/pageStart']
      }
      else if (this.$route.meta.isInSitu) {
        return this.$local('InSitu')
      }
      else {
        return {...this.$local('Pano'), params: { pano: '0' }}
      }
    },
    tutorials () {
      return this.$store.getters['pages/tutorials']
    },
    isInSitu () {
      return this.$store.state.data.isInSitu
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.tuto
  &__header
    font-size 25px
    line-height 30px

  &__content
    flex-grow: 1
    padding-top: $spacings.xxs
  
  &__img
    border: solid 1px $colors.white
  
  &__button
    +mq($from: 'desktop')
      width 100%
      @media (min-height: 850px)
        position absolute
        bottom: $spacings.sm
        left: $spacings.sm
        right: $spacings.sm
        width auto

</style>