import { Component } from 'shimmer'
import { polarToCartesian, cartesianToPolar } from '@/utils/Maths'

export class CameraLook extends Component {
  constructor (camera, isTouch = false) {
    super()

    this.camera = camera

    this.getPointerPosition = this.getPointerPosition.bind(this)
    this.onPointerdown = this.onPointerdown.bind(this)
    this.onPointerup = this.onPointerup.bind(this)
    this.onPointermove = this.onPointermove.bind(this)
    this.reinit = this.reinit.bind(this)
    this.isTouch = isTouch

    // const vector = this.camera.position.clone().sub(this.camera.look)
    const vector = this.camera.look.clone().sub(this.camera.position)
    const polar = cartesianToPolar(vector.x, vector.y, vector.z)
    this.polar = polar.polar
    this.azimuth = polar.azimuth

    this.delta = { x: 0, y: 0 }
  }

  onPointerdown (event) {
    this.isPointerdown = true
    this.cursorPosition = this.getPointerPosition(event)
  }

  onPointerup (event) {
    this.isPointerdown = false
    this.polar += this.delta.x
    this.azimuth += this.delta.y
  }

  onPointermove (event) {
    if (!this.isPointerdown) {return}

    const cursorPosition = this.getPointerPosition(event)
    this.delta.x = (this.cursorPosition.x - cursorPosition.x)
    this.delta.x *= this.isTouch ? .0037 : .001
    this.delta.y = (this.cursorPosition.y - cursorPosition.y)
    this.delta.y *= this.isTouch ? .0025 : .0008

    const look = polarToCartesian(1, this.delta.x + this.polar, this.delta.y + this.azimuth)

    this.camera.look.x = look.x + this.camera.position.x
    this.camera.look.y = look.y + this.camera.position.y
    this.camera.look.z = look.z + this.camera.position.z
  }

  reinit () {
    const vector = this.camera.look.clone().sub(this.camera.position)
    const polar = cartesianToPolar(vector.x, vector.y, vector.z)
    this.polar = polar.polar
    this.azimuth = polar.azimuth

    this.delta = { x: 0, y: 0 }
  }

  getPointerPosition (event) {
    let clientY = event.clientY
    let clientX = event.clientX

    if (event.touches) {
      clientY = event.touches[0].pageY
      clientX = event.touches[0].pageX
    }

    return {
      x: clientX,
      y: clientY
    }
  }

  // set container (el) {
  //   if (this.el !== void 0) {
  //     this.el.removeEventListener('pointerdown', this.onPointerdown)
  //     this.el.removeEventListener('pointerup', this.onPointerup)
  //     this.el.removeEventListener('pointermove', this.onPointermove)
  //   }

  //   this.el = el

  //   if(el !== void 0) {
  //     this.el.addEventListener('pointerdown', this.onPointerdown)
  //     this.el.addEventListener('pointerup', this.onPointerup)
  //     this.el.addEventListener('pointermove', this.onPointermove)
  //   }
  // }
}