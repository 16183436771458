<template>
  <div class="u-wrapper-vpanel u-row">
    <div class="end u-w3of12 u-w12of12--md u-flex u-column u-relative" :class="{'u-full-height u-bg-white': $device.isDesktop}">
      <div class="u-bg-white u-pad-sm">
        <div v-if="$device.isDesktop" class="u-marg-b-md">
          <div class="u-w4of12 u-box-16by9">
            <div class="u-wrapper-panel">
              <svg class="u-fill-black u-full-height u-full-width u-fit-contain">
                <use xlink:href="#dacia"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="t-h2 u-uppercase">{{ $t('end.well') }}</div>
        <div class="t-h2 u-uppercase t-regular">{{ $t('end.title') }}</div>
        <div class="t-text u-marg-t-xs">{{ $t('end.text1') }}</div>
        <div class="t-text t-bold u-uppercase u-marg-t-xxs">{{ $t('end.text2') }}</div>
        <div class="t-text u-marg-t-sm u-middle u-row">
          <input class="end__input u-marg-r-xxs" v-model="checked" type="checkbox" id="accept" name="accept">
          <label class="end__label" for="accept" v-html="$t('end.conditions')"></label>
        </div>
        <div class="t-text u-marg-t-sm">{{ $t('end.text3') }}</div>
      </div>

      <div v-if="!$device.isDesktop" class="end__img">
        <app-images class="u-fit-cover" :images="data.image" />
      </div>

      <transition name="t-fader">
        <app-button
          :white="$device.isDesktop ? false : true"
          :green="$device.isDesktop ? true : false"
          v-if="checked"
          :url="data.cta[0].url"
          class="end__button"
        >
          {{ data.cta[0].text }}
        </app-button>
      </transition>
    </div>
    <app-images v-if="$device.isDesktop" class="u-w9of12 u-fit-cover" :images="data.image" />
  </div>
</template>

<script>
import { audio } from '@/utils/Audio'

export default {
  data () {
    return {
      checked: false
    }
  },

  mounted () {
    this.$store.dispatch('data/reinit')

    // if (this.$store.getters['pages/sounds'].end) {
    //   audio.src = this.$store.getters['pages/sounds'].end
    //   audio.play()
    // }
  },

  computed: {
    data () {
      return this.$store.getters['pages/endpage']
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.end
  &__img
    flex-grow: 1

  &__input
    height 1em
    width 1em
    border solid 2px $colors.black

  &__label a
    text-decoration underline

  &__yolo
    font-size 14px !important

  &__button
    position absolute
    bottom: $spacings.sm
    left: $spacings.sm
    right: $spacings.sm
</style>