import { audio } from '@/utils/Audio'

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  },

  computed: {
    isUrlAbsolute () {
      if (!this.to.name && this.url.startsWith('http')) {
        return true
      }

      return false
    }
  },

  mounted () {
    this.$el.addEventListener('pointerdown', this._onClick)
  },

  beforeUnmount () {
    this.$el.removeEventListener('pointerdown', this._onClick)
  },

  methods: {
    _onClick () {
      // if (this.$store.getters['pages/sounds'].cta) {
      //   audio.src = this.$store.getters['pages/sounds'].cta
      //   audio.play()
      // }
    }
  }

}