<template>
  <div class="c-video-player u-overflow-hidden u-font-reset" :class="{'u-wrapper-panel': isWrapperPanel}">
    <video ref="video" :src="src" @ended="onVideoEnded" playsinline :class="{'u-fit-cover': isWrapperPanel, 'u-full-width': !isWrapperPanel}"/>
    <div v-if="!isPlaying" class="c-video-player__poster u-wrapper-panel" :class="{'u-bg-blue':poster !== ''}" @click="onTogglePlay">
      <img v-if="poster !== ''" :src="poster" class="u-fit-cover"/>
    </div>
    <!-- isPlaying -->
    <div class="u-wrapper-panel u-row u-middle u-center u-z-middle u-cursor-pointer" @click="onTogglePlay">
      <div class="c-video-player__play u-w1of12 u-box-1by1 u-round u-bg-red">
        <div class="u-wrapper-panel u-flex u-center u-middle">
          <div>
            <div class="t-text u-green t-bold">
              Play
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default
{
  props:
  {
    src: { type: String, default: '' },
    poster: { type: String, default: '' },
    forcePlay: { type: Boolean, default: false },
    controls: { type: Boolean, default: false },
    onPlay: { type: Function, default: ()=>{return} },
    onPause: { type: Function, default: ()=>{return} },
    muted: { type: Boolean, default: true },
    loop: { type: Boolean, default: false },
    isStoppable: { type: Boolean, default: true },
    isWrapperPanel: { type: Boolean, default: true }
  },

  data()
  {
    return {
      isPlaying: false,
      isFullScreen: false
    }
  },

  created() {
    this.hasPlay = false
  },

  mounted() {
    this.video = this.$refs.video

    if( this.video == void 0 ) { return }

    if( this.muted ){
      this.video.muted = true
      this.video.setAttribute('muted', '')
    }
    else{
      this.video.muted = false
    }

    if( this.forcePlay ) {
      this.video.setAttribute('muted', 'muted')
      this.video.setAttribute('autoplay', 'autoplay')
      this.play()
    }

    if( this.loop )
      this.video.setAttribute('loop', 'loop')
  },

  beforeDestroy () {
    window.removeEventListener('click', this.play)
  },

  watch: {
    forcePlay() {
      if( this.forcePlay ) {
        this.play()
      }
      else {
        this.pause()
      }
    },

    isPlaying() {
      if( this.isPlaying ) {
        this.$el.classList.remove('is-paused')
        this.$el.classList.add('is-started')
        this.$el.classList.add('is-playing')
      }
      else {
        this.$el.classList.add('is-paused')
        this.$el.classList.remove('is-playing')
      }
    }
  },

  methods: {
    checkPlay() {
      setTimeout( ()=>{
        if( !this.video.paused ){
          this.isPlaying = true
        }
        else {
          this.isPlaying = false

          if (this.forcePlay) {
            window.addEventListener('click', this.play)
          }
        }
      }, 400)
    },

    onTogglePlay() {
      if( !this.isStoppable || this.video == void 0 ) { return }

      if(this.isPlaying) {
        this.pause()
      }
      else {
        this.play()
      }
    },

    play() {
      this.hasPlay = true
      this.video.play()
      this.checkPlay()
    },

    pause() {
      this.isPlaying = false
      this.video.pause()
    },

    onVideoEnded() {
      this.$el.classList.remove('is-started')

      if( this.loop ) {
        this.play()
        this.video.currentTime = 0
        this.play()
      }
    },
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'

.c-video-player__play
  transform scale(1) translateZ(0)
  transition transform .9s ease-out-quint, opacity .5s
  min-width 100px

  .is-playing &
    opacity 0
    // transition transform .9s ease-out-quint, opacity .5s

.c-video-player__poster
  transform translateZ(0)
  transition opacity .5s linear

  .is-started &
    opacity 0

.c-video-player__controls
  z-index 2
  opacity 0
  transform translateZ(0)

  .is-started &
    opacity 1
    transition opacity .6s

</style>
