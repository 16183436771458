<template>
  <div class="indice-tuto u-bg-red u-green u-row u-middle u-radius u-pad-md">
    <div class="u-w3of12 u-box-1by1">
      <img class="u-wrapper-panel u-fit-contain" :src="img">
    </div>
    <div class="u-w9of12 t-text u-pad-l-sm">
      {{ text }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      img: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.indice-tuto
  position absolute
  bottom $spacings.md
  right $spacings.md
  width 400px
</style>