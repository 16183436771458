<template>
  <div class="u-wrapper-vpanel u-pad-sm--md" @click="$emit('close')">
    <panel @close="$emit('close')" :data="data" />
  </div>
</template>

<script>
import Panel from '@/components/Panel'

export default {
  components: {
    Panel
  },

  computed: {
    data () {
      return this.$store.getters['data/keypoint'](this.$route.params.media)
    }
  },

  props: {
    transition: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      popinMedia: '',
      isPanelOpen: false
    }
  },

  mounted () {
    this.onInit()
  },

  methods: {
    onInit () {
      this.$ga.send('navigation', 'Keypoint', 'media', this.$route.params.media)
      this.$store.commit('data/completed', { keypoint: this.data })
    },
  },

  watch: {
    $route (to, from) {
      if (to.name.includes('Media')) {
        this.onInit()
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.keypoint
  &__button
    position absolute
    top 50%
    left 30px
    height 80px
    width 80px
    padding 23px
    border-radius 50%
    
    &--right
      left auto
      // 450 + 30
      right 480px

</style>