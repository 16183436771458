<template>
  <div class="page u-wrapper-panel u-black u-bg-white u-pad-sm u-z-front">
    <div class="u-relative">
      <div class="t-h1 u-marg-b-sm">{{ $t('pages.' + name) }}</div>
      <svg aria-label="close" @click="$emit('close')" class="page__close u-cursor-pointer">
        <use xlink:href="#close"/>
      </svg>

      <div class="u-row">
        <div class="u-w6of12 u-w12of12--sm">
          <div class="t-text u-marg-b-sm u-black">{{ $t('pages.legals1') }}</div>
          <div class="t-text u-marg-b-sm u-black">{{ $t('pages.legals2') }}</div>
          <div class="t-text u-marg-b-sm u-black">{{ $t('pages.legals3') }}</div>
          <div class="t-text u-marg-b-sm u-black">{{ $t('pages.legals4') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.page
  &__close
    position absolute
    right: 0
    top: 0
    height 25px
    width 25px
</style>