// https://discourse.threejs.org/t/how-to-render-360-degree-images-on-three-js-without-noize-like-panolens-js/21020/2

import { CubeTextureLoader, TextureLoader, EquirectangularReflectionMapping, CubeReflectionMapping } from 'three'

export class TextureLib {
  loadMap (url) {
    return new Promise(resolve => {
      const loader = new TextureLoader()
      loader.load(url, texture => {
        // texture.mapping = EquirectangularReflectionMapping
        // texture.offset.set = true
        resolve(texture)
      })
    })
  }

  loadCubemap (urls) {
    if (urls.length < 6) {
      console.warn('Not enouth images')
      return
    }

    return new Promise(resolve => {
      new CubeTextureLoader().load(urls, texture => {
        texture.mapping = CubeReflectionMapping
        resolve(texture)
      })
    })
  }
}

export const textureLib = new TextureLib()