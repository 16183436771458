<template>
  <div class="clickable"></div>
</template>

<script>
import Object3d from '@/plugins/Dom3d/mixin'

export default {
  mixins: [ Object3d ]
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.clickable
  width 2px
  height 2px
  position absolute
  pointer-events none !important // needed to bypass threejs automatique inline style

  &:after
    content ''
    position absolute
    opacity .5
    top 0
    left 0
    height 100%
    width 100%
    border-radius 50%
    background-color: $colors.green
</style>