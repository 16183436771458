<template>
  <div class="u-wrapper-vpanel u-pointer-none u-z-middle">
    <div class="u-w3of12 u-w12of12--md u-relative u-full-height">
      <div class="header-pano u-full-height">
        <div ref="headerPano" class="header-pano__top u-bg-white u-relative u-z-back js-header-pano ">
          <div v-if="$device.isDesktop" class="u-marg-b-xs">
            <div class="u-w4of12 u-box-16by9">
              <div class="u-wrapper-panel">
                <svg class="u-fill-black u-full-height u-full-width u-fit-contain">
                  <use xlink:href="#dacia"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="t-h3 t-read u-orange">
            {{ $t('step') }} {{ questionAnswered + 1 }}/{{ totalQuestions - nbrPass }}
          </div>
          <transition name="t-fader">
            <div v-if="!hidePicto" class="header-pano__picto u-bg-orange">
              <svg v-if="isQuestion" class="u-wrapper-panel u-fill-white u-fit-contain" preserveAspectRatio="xMidYMid meet">
                <use xlink:href="#?"/>
              </svg>
              <svg v-else class="u-wrapper-panel u-fill-white u-fit-contain" preserveAspectRatio="xMidYMid meet">
                <use xlink:href="#i"/>
              </svg>
            </div>
          </transition>
        </div>
        <transition name="t-translate--down">
          <div v-show="$device.isDesktop || (!isTransition && !isQuestion)" class="header-pano__bottom__wrapper u-bg-grey u-relative u-overflow-hidden">
            <transition name="t-translate--down">
              <div v-show="!isTransition && !isQuestion" class="header-pano__bottom u-pad-t-sm--md u-pad-y-md u-pad-x-sm u-full-height">
                <div class="t-h3">
                  Indice
                </div>
                <div class="t-text u-marg-t-xxs" v-html="currentQuestion.multipleTexts[0]?.text" />
              </div>
            </transition>
          </div>
        </transition>
      </div>
      <transition name="t-fader">
        <div v-if="!isQuestion && passPossible && !isInSitu" class="header-pano__cta u-between u-row">
          <div class="u-w8of12 u-pad-r-xs">
            <app-button class="header-pano__cta__wrapper u-pointer-all" @click="onNext" :white="true">
              <div class="header-pano__cta__shitty u-uppercase">
                {{ $t('help.1') }}
              </div>
              <div class="header-pano__cta__shittyinred u-red u-uppercase">
                {{ $t('help.2') }}
              </div>
            </app-button>
          </div>
          <app-button @click="onNext" :red="true" class="u-w4of12 u-pointer-all header-pano__cta__item">
            <svg class="u-fill-white header-pano__cta__svg" preserveAspectRatio="xMidYMid meet">
              <use xlink:href="#arrow-right"/>
            </svg>
          </app-button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { audio } from '@/utils/Audio'
import { nbrPass, nbrPassInsitu } from '@/assets/data'

export default {
  props: {
    isQuestion: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isTransition: false,
      hidePicto: false,
      heightPano: 0
    }
  },

  computed: {
    questionAnswered () {
      return this.$store.getters['data/questionAnswered']
    },
    totalQuestions () {
      return this.$store.getters['data/questions'].length
    },
    currentQuestion () {
      return this.$store.getters['data/currentQuestion']
    },
    passPossible () {
      return this.$store.getters['data/passPossible']
    },
    nbrPass () {
      return this.$store.state.data.isInSitu ? nbrPassInsitu : nbrPass
    },
    isInSitu () {
      return this.$store.state.data.isInSitu
    }
  },

  mounted () {
    this.onResize()
    this.$hub.on('answer', () => {
      if (this.$device.isDesktop) {
        this.hidePicto = true
      }
    })
  },

  methods: {
    onResize () {
      this.heightPano = this.$refs.headerPano.offsetHeight + 'px'
    },

    onNext () {
      this.$store.commit('data/passQuestion')
      this.isTransition = true

      setTimeout(() => {
        this.$store.dispatch('data/currentQuestionIndexNext')
        this.isTransition = false

        // if (this.$store.getters['pages/sounds'].indice) {
        //   audio.src = this.$store.getters['pages/sounds'].indice
        //   audio.play()
        // }
      }, 1000)
    }
  },

  watch: {
    $route () {
      this.hidePicto = false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/mixins/mq'

pictoSize = 60px

.header-pano
  position absolute
  top 0
  left 0
  width 100%

  &__top
    padding: $spacings.sm $spacings.sm $spacings.xs $spacings.sm

    +mq($until: 'desktop')
      padding: $spacings.xxs $spacings.sm $spacings.xxs $spacings.sm

  &__bottom    
    &__wrapper 
      +mq($from: 'desktop')
        height calc(100% - v-bind(heightPano))

  &__picto
    position absolute
    right $spacings.sm
    bottom pictoSize * -0.5
    padding 10px
    height pictoSize
    width pictoSize
    
    > svg
      height 60%
      width 60%
      top 20%
      left 20%

  &__cta
    position absolute
    bottom: $spacings.sm
    left: $spacings.sm
    right: $spacings.sm
    
    &__wrapper
      .t-button__content
        padding 12px 5px
    
    &__item
      max-width 150px

    &__shitty
      font-family: dacia
      font-weight: $weights.regular
      line-height 1.4em
      viewportSizer('font-size', 18, 1920)

      +mq($until: 'tablet')
        font-size 18px
    
    &__shittyinred
      font-family: dacia
      font-weight: $weights.bold
      line-height 1.4em
      viewportSizer('font-size', 16, 1920)

      +mq($until: 'tablet')
        font-size 16px
    
    &__svg
      height 50px
      width 100%

</style>