import { createApp, mixin } from 'vue'
import { createRouter } from 'vue-router'

import { createI18n } from 'vue-i18n'

import { store } from '@/store'
import { api } from '@/store/interfaces/apiCraft'
import { router } from './router'

import Local from '@/plugins/Local'
import Device from '@/plugins/Device'
import Hub from '@/plugins/Hub'
import Offset from '@/plugins/Offset'
import Dom3d from '@/plugins/Dom3d'
import GoogleAnalytics from '@/plugins/GoogleAnalytics'

// import VueAuth0Plugin from 'vue-auth0-plugin'

import messages from '@/locales'

import AppImage from '@/components/common/AppImage'
import AppImages from '@/components/common/AppImages'
import AppButton from '@/components/common/AppButton'

import extendedComponent from '@/mixins/extendedComponent'

import { emitter } from '@/utils/emitter'
import { setup } from '@/assets/data'

import { storage } from '@/utils/storage'

import App from './App.vue'

class VueArtwork {
  constructor () {
    this.setLang()

    this.app = createApp(App)

    // router
    this.router = createRouter(router)
    this.app.use(this.router)

    // i18n
    this.i18n = createI18n({
      locale: this.local.lang,
      messages
    })
    this.app.use(this.i18n)

    // VueX
    this.app.use(store)

    // Mixin
    this.app.mixin(extendedComponent)

    // Handmade Plugins
    this.app.use(Local)
    this.app.use(Device)
    this.app.use(Hub)
    this.app.use(Offset)
    this.app.use(Dom3d)
    this.app.use(GoogleAnalytics)

    this.app.component('app-image', AppImage)
    this.app.component('app-images', AppImages)
    this.app.component('app-button', AppButton)

    this.app.mount('#app')

    this.router.beforeEach(this.beforeGuard)
  }

  setLang () {
    const isFr = /^fr\b/.test(window.navigator.userLanguage || window.navigator.language)
    this.local = {
      lang: isFr ? 'fr' : 'en',
      website: isFr ? process.env.VUE_APP_CRAFT_WEBSITE_FR : process.env.VUE_APP_CRAFT_WEBSITE_EN
    }
    api.local = this.local
  }

  beforeGuard (to, from, next) {
    if (!from.name) {
      if (to.name.includes('InSitu/Question')) {
        storage.getItem('seenItems').then(res => {
          if (res === null) {
            store.commit('global/pageStart', {name: to.name, params: to.params})
            next({name: 'InSitu/Homepage'})
          }
          else {
            next()
          }
        })
      }
      else {
        next()
      }
    }
    else {
      if (to.name === 'Pano' && from.name == 'Pano') {
        emitter.emit('transition:on', {to, from})
        emitter.once('transition:off', next)

        return
      }

      next()
    }
  }
}

new VueArtwork()
