<template>
  <div
    class="article u-left u-pad-y-sm u-z-back u-pad-x-sm u-bg-white"
    :class="{
      'u-overflow-y-scroll u-invisible-scroll u-full-height u-pad-b-sm': isFull || !$device.isTablet
    }"
  >
    <div v-if="$device.isDesktop" class="u-marg-b-md">
      <div class="u-w4of12 u-box-16by9">
        <div class="u-wrapper-panel">
          <svg class="u-fill-black u-full-height u-full-width u-fit-contain">
            <use xlink:href="#dacia"/>
          </svg>
        </div>
      </div>
    </div>
    <svg @click="$emit('close')" class="article__close u-cursor-pointer">
      <use xlink:href="#close"/>
    </svg>
    <div>
      <div class="article__titles">
        <div class="u-black u-marg-b-xxs u-pad-r-md t-h2">
          {{data.title}}
        </div>
        <div class="u-black t-h4">
          {{data.subtitle}}
        </div>
      </div>
    </div>
    <template v-if="!isFull">
      <div
        v-if="data.multipleTexts.length"
        v-html="data.multipleTexts[0].text"
        class="t-wysiwyg u-marg-t-sm u-black"
      />
      <div @click="$emit('full')" class="t-link u-marg-t-xs u-uppercase">
        {{ $t('keypoint.more')}}
      </div>
    </template>
    <template v-else>
      <!-- <div v-if="data.video?.length || data.image?.length" class="article__media u-radius u-overflow-hidden u-marg-t-md">
        <div v-if="data.video?.length" class="u-box-16by9">
          <video-player :src="data.video[0].url" />
        </div>
        <app-images
          v-else
          :images="data.image"
          :width="600"
          class="u-block u-full-width u-radius u-cursor-pointer"
        />
      </div> -->
      <template v-for="(text, index) in data.multipleTexts">
        <div
          v-if="index !== 0"
          class="t-wysiwyg u-marg-t-sm u-black"
          v-html="text.text"
        />
      </template>
    </template>
  </div>
</template>

<script>
import MediaType from '@/mixins/MediaType'

import VideoPlayer from '@/components/common/players/VideoPlayer'
import Category from '@/components/common/Category'
import AppDrag from '@/components/common/AppDrag'
import AppButton from '@/components/common/AppButton'
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  mixins: [MediaType],

  components: {
    VideoPlayer,
    Category,
    AppDrag,
    AppButton,
    AppButtonRound
  },

  props: {
    isFull: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.article
  position absolute
  top 0
  left 0
  width 100%
  z-index 0

  &__arrow
    height 1em
    width 2em
    
  &__close
    right: $spacings.sm
    top: $spacings.lg
    position absolute
    height 25px
    width 25px

    +mq($until: 'desktop')
      right: $spacings.sm
      top: $spacings.sm
      height 20px
      width 20px

</style>