<template>
  <div
    class="t-button-quizz u-cursor-pointer u-left t-text u-relative"
    :class="{'is-true': isTrue, 'is-selected': isSelected, 'is-selecting': isSelecting, 'focus': focus}"
    @click="$emit('select')"
  >
    <div class="t-button-quizz__notch" />
    <input @focus="focus=true" @blur="focus=false" type="radio" :name="slugify(name)" :value="slug" :id="slug">
    <label :for="slug" class="t-button-quizz__content u-block u-relative u-cursor-pointer">
      {{ label }}
    </label>
  </div>
</template>

<script>
import Button from './Button'
import slugify from 'slugify'

export default {
  mixins: [ Button ],

  props: {
    isTrue: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isSelecting: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },

  computed: {
    slug () {
      return this.slugify(this.label)
    }
  },

  data () {
    return {
      focus: false
    }
  },

  mounted () {
    this.$el.addEventListener(this.$device.pointerdown, this.onPointerDown)
  },

  beforeUnmount () {
    this.$el.removeEventListener(this.$device.pointerdown, this.onPointerDown)
  },

  methods: {
    slugify (str) {
      return slugify(str).toLowerCase()
    },

    onPointerDown () {
      this.$emit('select')
    },
    onKeydown (event) {
      if (event === 13 && this.focus) {
        this.$emit('select')
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

// duration = v-bind(durationString)
duration = .6s
colorTrue = #646B52
colorFalse = #B9412D


notchSize = 15px

.t-button-quizz
  z-index 0
  position relative
  background-color transparent
  color $colors.black
  transition color ease-in-out-quad .3s

  &__content
    z-index 1
    padding 15px 20px
  
  // BEFORE
  // +-------------+
  // |xxxxxxxxxxxxx|
  // |xxxxxxxxxxxxx|
  // |            /
  // +-----------/
  &:before
    content ''
    top 0
    left 0
    right 0
    bottom notchSize
    position absolute
    background-color: $colors.white
    transition background ease-in-out-quad .3s
    
  // AFTER
  // +-------------+
  // |xxxxxxxxxxx  |
  // |xxxxxxxxxxx  |
  // |xxxxxxxxxxx /
  // +-----------/
  &:after
    content ''
    top 0
    left 0
    right notchSize
    bottom 0
    position absolute
    background-color: $colors.white
    transition background ease-in-out-quad .3s

  // NOTCH
  // +-------------+
  // |             |
  // |             |
  // |           xx
  // +-----------x
  &__notch
    position absolute
    bottom 0
    right 0
    height notchSize
    width notchSize
    border-top: notchSize solid $colors.white
    border-right notchSize solid transparent
    transition border ease-in-out-quad .3s
  
  &:hover, &.is-selecting
    color: $colors.white

    &:before, &:after
      background-color: $colors.black

    .t-button-quizz__notch
      border-top: notchSize solid $colors.black

  // TRUE
  &.is-selected.is-true
    color: $colors.white

    &:before, &:after
      background-color: colorTrue

    .t-button-quizz__notch
      border-top: notchSize solid colorTrue

  // FALSE
  &.is-selected
    color: $colors.white

    &:before, &:after
      background-color: colorFalse

    .t-button-quizz__notch
      border-top: notchSize solid colorFalse

  input
    // visibility hidden
    height 0
    width 0
    position absolute

</style>