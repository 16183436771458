
<template>
  <component
    :is="url || to.name ? 'div' : 'button'"
    class="t-button u-inline-block u-cursor-pointer u-center"
    :class="{
      'is-white': white,
      'is-green': green,
      'is-red': red,
      'is-icon': icon,
      'is-small': small,
      'focus': focus
    }"
  >
    <div v-if="!small" class="t-button__notch" />

    <template v-if="url !== ''" >
      <a v-if="isUrlAbsolute" @focus="focus=true" @blur="focus=false" :href="url" target="_blank" :class="metaClass">
        <slot/>
      </a>
      <router-link v-else @focus="focus=true" @blur="focus=false" :to="url" :class="metaClass">
        <slot/>
      </router-link>
    </template>
    <router-link v-else-if="to.name" @focus="focus=true" @blur="focus=false" :to="to" :class="metaClass">
      <slot/>
    </router-link>
    <span v-else :class="metaClass">
      <slot/>
    </span>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  computed: {
    metaClass () {
      let classs =  this.icon ? 'u-row u-center u-middle' : ''

      classs += this.small ? 't-cta--small' : 't-cta'

      return `${classs} ${this.innerClass} t-button__content u-relative u-block`
    }
  },

  data () {
    return {
      focus: false
    }
  },

  props: {
    icon: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    },
    green: {
      type: Boolean,
      default: false
    },
    red: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    innerClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/easings'

notchSize = 15px

.t-button
  z-index 0
  position relative
  background-color transparent
  color $colors.white
  transition color ease-in-out-quad .3s
  
  &__content
    padding 20px
  
  // BEFORE
  // +-------------+
  // |xxxxxxxxxxxxx|
  // |xxxxxxxxxxxxx|
  // |            /
  // +-----------/
  &:before
    content ''
    top 0
    left 0
    right 0
    bottom notchSize
    position absolute
    background-color: $colors.black
    transition background ease-in-out-quad .3s
    
  // AFTER
  // +-------------+
  // |xxxxxxxxxxx  |
  // |xxxxxxxxxxx  |
  // |xxxxxxxxxxx /
  // +-----------/
  &:after
    content ''
    top 0
    left 0
    right notchSize
    bottom 0
    position absolute
    background-color: $colors.black
    transition background ease-in-out-quad .3s

  // NOTCH
  // +-------------+
  // |             |
  // |             |
  // |           xx
  // +-----------x
  &__notch
    position absolute
    bottom 0
    right 0
    height notchSize
    width notchSize
    border-top: notchSize solid $colors.black
    border-right notchSize solid transparent
    transition border ease-in-out-quad .3s
  
  &:hover
    color: $colors.black

    &:before, &:after
      background-color: $colors.white

    .t-button__notch
      border-top: notchSize solid $colors.white

  // WHITE
  &.is-white
    color: $colors.black

    &:before, &:after
      background-color: $colors.white

    .t-button__notch
      border-top: notchSize solid $colors.white
    
    &:hover
      color: $colors.white

      &:before, &:after
        background-color: $colors.black

      .t-button__notch
        border-top: notchSize solid $colors.black

  // GREEN
  &.is-green
    color: $colors.white

    &:before, &:after
      background-color: $colors.green

    .t-button__notch
      border-top: notchSize solid $colors.green
    
    &:hover
      color: $colors.black

      &:before, &:after
        background-color: $colors.white

      .t-button__notch
        border-top: notchSize solid $colors.white

  // GREEN
  &.is-red
    color: $colors.white

    &:before, &:after
      background-color: $colors.red

    .t-button__notch
      border-top: notchSize solid $colors.red
    
    &:hover
      color: $colors.white

      &:before, &:after
        background-color: $colors.black

      .t-button__notch
        border-top: notchSize solid $colors.black

  // SMALL
  &.is-small
    padding 10px 15px

    &:after
      content none
    &:before
      top 0
      left 0
      right 0
      bottom 0

  &__content
    z-index 1

</style>