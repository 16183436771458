<template>
  <div class="debug"></div>
</template>

<script>
import Object3d from '@/plugins/Dom3d/mixin'

export default {
  mixins: [ Object3d ]
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.debug
  width 3px
  height 3px
  position absolute
  pointer-events none !important // needed to bypass threejs automatique inline style

  &:after
    content ''
    position absolute
    top 0
    left 0
    height 100%
    width 100%
    border-radius 50%
    background url('/images/test.svg')
    background-size cover
</style>