import { storage, cookie } from '@/utils/storage'
import { scoring } from '@/assets/data'

export default {
	namespaced: true,

	state: {
		score: 0,
		isFistVisit: true,
		sound: true,
		pageStart: false
	},

	getters: {
		score (state) {
			return state.score
		},
		isTutoSeen (state) {
			return state.isTutoSeen
		},
		pageStart (state) {
			return state.pageStart
		}
	},

	mutations: {
		right (state) {
			state.score += scoring.right
		},
		wrong (state) {
			state.score += scoring.wrong
		},
    isTutoSeen (state, payload) {
      state.isTutoSeen = payload
      cookie.setItem('tuto_seen', payload)
    },
    isInSitu (state, payload) {
      state.isInSitu = payload
    },
    sound (state, payload) {
      state.sound = payload
    },
    pageStart (state, payload) {
      state.pageStart = payload
    }
	}
}