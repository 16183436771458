import { createWebHistory } from 'vue-router'
import { AuthenticationGuard } from 'vue-auth0-plugin'

import Styleguide from './views/Styleguide'
import Introduction from './views/Introduction'
import Homepage from './views/Homepage'
import Tuto from './views/Tuto'
import Media from './views/keypoint/Media'
import Keypoint from './views/Keypoint'
import Pano from './views/Pano'
import InSitu from './views/InSitu'
import End from './views/End'

import messages from './locales'

const routes = [
  {
    path: 'styleguide',
    name: 'Styleguide',
    component: Styleguide,
    meta: {
      isFull: false
    }
  },
  {
    path: '',
    name: 'Homepage',
    component: Homepage,
    meta: {
      isFull: true
    }
  },
  {
    path: 'in-situ/homepage',
    name: 'InSitu/Homepage',
    component: Homepage,
    meta: {
      isFull: true,
      isInSitu: true
    }
  },
  {
    path: 'tutorials',
    name: 'Tuto',
    component: Tuto,
    meta: {
      isFull: true
    }
  },
  {
    path: 'in-situ/tutorials',
    name: 'InSitu/Tuto',
    component: Tuto,
    meta: {
      isFull: true,
      isInSitu: true
    }
  },
  {
    path: 'end',
    name: 'End',
    component: End,
    meta: {
      isFull: true
    }
  },
  {
    path: 'in-situ/end',
    name: 'InSitu/End',
    component: End,
    meta: {
      isFull: true,
      isInSitu: true
    }
  },
  {
    path: 'in-situ',
    name: 'InSitu',
    component: InSitu,
    meta: {
      isFull: true,
      isInSitu: true
    }
  },
  // {
  //   path: 'in-situ/edito/:media',
  //   name: 'InSitu/Media',
  //   component: InSitu,
  //   meta: {
  //     isFull: true
  //   }
  // },
  {
    path: 'in-situ/:question',
    name: 'InSitu/Question',
    component: InSitu,
    meta: {
      isFull: true
    }
  },
  {
    path: ':pano',
    name: 'Pano',
    component: Pano,
    meta: {
      isFull: true
    }
  },
  {
    path: ':pano/edito/:media',
    name: 'Media',
    component: Pano,
    meta: {
      isFull: true
    }
  },
  {
    path: ':pano/question/:question',
    name: 'Question',
    component: Pano,
    meta: {
      isFull: true
    }
  }
]

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  // for (const locale of Object.keys(messages)) {
  //   const prefix = locale === 'en' ? '' : locale + '/'
  //   let route = {...routes[i]}
  //   route.path = '/' + prefix + route.path
  //   route.name = prefix + route.name
  //   route.meta = {
  //     ...routes[i].meta,
  //     locale: locale
  //   }

  //   langRoutes.push(route)
  // }
  langRoutes.push({...routes[i], path: '/' + routes[i].path })
}

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: langRoutes
}
