<template>
  <router-link :to="to" class="arrow u-absolute">
    <img draggable="false" class="u-wrapper-panel" src="/images/arrow.png">
  </router-link>
</template>

<script>
import Object3d from '@/plugins/Dom3d/mixin'

export default {
  mixins: [ Object3d ],

  props: {
    to: {
      type: Object,
      required: true
    }
  }
}
</script>


<style lang="stylus">
@import '~@/styles/settings/variables'

.arrow
  width 200px
  height 200px
  display block

  .is-mobile &
    width 20px
    height 20px
</style>