<template>
  <div
    :style="{height: height + 'px'}"
    class="header u-full-width u-pad-x-sm u-z-front"
    :class="{'is-bg': !$route?.name?.includes('Media')}"
  >
    <div class="u-relative u-full-height u-full-width u-row u-middle u-between">
      <router-link class="u-w4of12 u-w2of12--md u-box-16by9" :to="$local($route.meta.isInSitu ? 'InSitu/Homepage' : 'Homepage')">
        <div class="u-wrapper-panel">
          <svg class="u-fill-black u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#dacia"/>
          </svg>
        </div>
      </router-link>
      <div class="u-w3of12 u-w2of12--md u-row u-middle u-right">
        <!-- <sound :bottom="true" class="u-w3of12" color="black" /> -->
        <div
          class="u-w8of12 u-box-16by9"
          @click="$emit('menu')"
          svg="menu"
          :stroke="true"
        >
          <div class="u-wrapper-panel u-pad-l-xs">
            <svg class="u-fill-black u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
              <use xlink:href="#menu"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'
import Sound from '@/components/common/Sound'

export default {
  components: {
    AppButtonRound,
    Sound
  },

  props: {
    height: {
      type: Number,
      default: 0
    }
  },

  computed: {
    superKeypoint () {
      return this.$store.getters['data/keypoint'](this.$route.params.super)
    },
    titleHomepage () {
      return this.$store.getters['pages/homepage'].title
    },
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.header
  position absolute
  top 0
  left 0
  // border-bottom solid 1px #bbbbbb
  &:before
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-color: $colors.white
    opacity 0
    transition opacity .5s linear

  &__logo
    width 100px
  
  &.is-bg:before
    opacity 1
</style>