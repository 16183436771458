<template>
  <div class="insitu-view u-wrapper-panel u-bg-green">
    <transition name="t-fader">
      <header-pano :isQuestion="$route.name.includes('Question')" />
    </transition>

    <div class="u-wrapper-vpanel">
      <qr-code-reader v-if="!$route.name.includes('Question')" @scan="onScan" />
    </div>

    <!-- <transition :name="$route.name.includes('Media') ? 't-translate--down--delayed' : 't-fader'">
      <media v-if="$route.name.includes('InSitu/Media')" @close="onClose" />
    </transition> -->

    <transition name="t-translate--down--delayed">
      <question v-if="$route.name.includes('InSitu/Question')" @close="onClose" :isInSitu="true" />
    </transition>
  </div>
</template>

<script>
import HeaderPano from '@/components/HeaderPano'

import QrCodeReader from '@/components/QrCodeReader'

import Media from '@/views/keypoint/Media'
import Question from '@/views/keypoint/Question'

import { audio } from '@/utils/Audio'

export default {
  data () {
    return {
    }
  },

  components: {
    QrCodeReader,
    HeaderPano,
    Media,
    Question
  },

  created () {
    this.route = this.$router.getRoutes().find(route => route.name.includes('InSitu'))
  },

  methods: {
    onScan (res) {
      res = res.split('/')
      const indice = res[res.length - 1]

      // if (this.$store.getters['pages/sounds'].qrCode) {
      //   audio.src = this.$store.getters['pages/sounds'].qrCode
      //   audio.play()
      // }

      this.$router.push({...this.$local('InSitu/Question'), params: { question: indice }})
    },

    onClose () {
       this.$router.push(this.$local('InSitu'))
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.debug-panel
  position absolute
  bottom 0
  left 0
  z-index 100
  // width 100px

.keypoint-view
  &__switch
    position absolute
    top $spacings.md
    left 50%
    transform translateX(-50%)
</style>