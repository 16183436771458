export const assets = [
  {
    name: "env",
    url: "/test.glb"
  }
]

export const setup = {
  mainColor: "#ff0000",
  secondColor: "#00ff00",
  duration: 2.2
}

export const scoring = {
  right: 10,
  wrong: -5
}

export const questionView = [10, 8, 9, 11, 1, 4]

export const nbrPass = 1
export const nbrPassInsitu = 1

// more aperture it is,
// smaller is the focused zone
export const postProcess = {
  apertureMax: 60,
  useBokeh: false,
  useBloom: false
}

export const autoRenderLeveling = true

export const renderLevels = [
  ['NO_POSTPROCESS', 'NO_RETINA', 'OBJ_LOW', 'NO_SHADOW', 'RESOLUTION_LOW'],
  ['NO_POSTPROCESS', 'NO_RETINA', 'OBJ_LOW', 'NO_SHADOW'],
  ['NO_POSTPROCESS', 'NO_RETINA'],
  ['NO_POSTPROCESS'],
  []
]

export const annexes = [
  {
    name: 'personal',
    active: true,
    url: 'https://www.dacia.fr/donnees-personnelles.html'
  },
  {
    name: 'cookies',
    active: true,
    url: 'https://www.dacia.fr/cookies.html'
  },
  {
    name: 'about',
    active: true,
  }
]