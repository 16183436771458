<template>
  <component
    :is="to.name ? 'router-link' : url !== '' ? 'a' : 'div'"
    :to="to"
    :href="url"
    :target="isUrlAbsolute ? '_blank' : ''"
    class="button-round u-inline-block u-relative u-box-1by1 u-cursor-pointer"
    :name="name"
    :class="{
      'is-white': white
    }"
  >
    <div class="button-round__wrapper u-wrapper-panel">
      <svg
        class="u-full-width u-full-height u-relative"
        :class="white ? 'u-fill-white' : 'u-fill-black'"
        preserveAspectRatio="xMidYMid meet"
      >
        <use :xlink:href="'#' + svg"/>
      </svg>
    </div>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  computed: {
    color () {
      if (this.ghost && this.invert) {
        return 'red'
      }
      return this.ghost || this.invert ? 'green' : 'red'
    }
  },

  props: {
    svg: {
      type: String,
      default: ''
    },
    white: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.button-round
  width 70px
  border: solid 1px $colors.black
  
  &:after
    content ''
    top 0
    left 0
    width 100%
    height 100%
    position absolute
    transition opacity .3s linear
    opacity 0
    background-color: $colors.white
  
  &:hover:after
    opacity 1

  // WHITE
  &.is-white
    border: solid 1px $colors.white

    &:after
      background-color: $colors.black
  
  &__wrapper
    z-index 1
    padding 25%

    +mq($until: 'tablet')
      padding 30%

</style>