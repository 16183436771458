<template>
  <div class="u-wrapper-panel">
    <video ref="video" class="u-wrapper-panel u-fit-cover" />
    <!-- <div class="u-wrapper-panel u-row u-bottom u-left">
      <div class="t-h1">
        scanned: {{ scan }}
      </div>
    </div> -->
  </div>
</template>

<script>
// https://nimiq.github.io/qr-scanner/
import QrScanner from 'qr-scanner'

export default {
  data () {
    return {
      scan: ''
    }
  },

  mounted () {
    this.qrScanner = new QrScanner(
      this.$refs.video,
      this.onScan,
      {
        preferredCamera: 'environment',
        highlightScanRegion: false,
        highlightCodeOutline: true
      }
    )

    this.qrScanner.start()
  },

  methods: {
    onScan (res) {
      this.scan = res.data
    }
  },

  beforeUnmount () {
    this.qrScanner.destroy()
  },

  watch: {
    scan () {
      // fired once since clearcode is not erased when no qrcode scanned
      // cause qrcode is not scanned twice of the time
      this.$emit('scan', this.scan)
    }
  }

}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.scan-region-highlight-svg
  // not used anymore
  stroke white !important

.code-outline-highlight
  stroke $colors.white !important
</style>


