import { Component } from 'shimmer'

import { Renderer } from './Renderer'

import {
  Scene,
  Vector3,
  Vector2,
  AxesHelper,
} from 'three'

import { PerspectiveCamera } from 'shimmer'
import { emitter } from '@/utils/emitter'

import { textureLib } from '@/webGL/TextureLib'

class WebGL extends Component {
  constructor () {
    super()

    this.resize = this.resize.bind(this)
    this.onQuality = this.onQuality.bind(this)
    this.camUpdate = this.camUpdate.bind(this)

    this.scene = new Scene()

    this.camera = new PerspectiveCamera(50, 1, 1, 1000)
    this.camera.far = 5000
    this.camera.lookAt( new Vector3(0, 0, 0) )

    this.renderer = new Renderer(this.scene, this.camera)

    emitter.on('level:change', this.onQuality)

    this.start()
    // this.addGui()
  }

  update () {
    this.camera.update()
    this.renderer.update()
  }

  start () {
    this.resize()
  }

  resize (args = {}) {
    args.width = args.width !== void 0 ? args.width : window.innerWidth
    args.height = args.height !== void 0 ? args.height : window.innerHeight
    this.renderer.resize(args)
  }

  onQuality () {
    this.renderer.renderQuality()

    this.resize()
  }

  onDestroy () {
    this.destroy()
    this.renderer.onDestroy()
  }

  addGui () {
    const folder = gui.addFolder('camera')
  }

  camUpdate () {
    this.camera.fov = guiObj.fov
    this.camera.zoom = guiObj.zoom
    this.camera.updateProjectionMatrix()
  }

  set el (el) {
    this.container = el
    this.container.appendChild(this.renderer.canvas)
  }

  set texture (texture) {
    this.scene.background = texture
  }
}

export const webGL = new WebGL()