<template>
  <div @click="onToggle" class="sound u-row u-between u-cursor-pointer">
    <div
      v-for="bar in bars"
      class="sound__bar"
      :class="['u-bg-' + color, {'is-bottom': bottom}]"
      :style="{ width: `calc(${100/bars.length}% - ${marg}px`, transform: `scaleY(${bar}) translateZ(0)`, transitionDuration: duration + 's' 
      }"
    />
  </div>
</template>

<script>
import { randomRange } from '@/utils/Maths'

export default {
  data () {
    return {
      bars: [0, 0, 0, 0],
      duration: 0,
      marg: 1.5
    }
  },

  props: {
    color: {
      type: String,
      default: 'white'
    },
    bottom: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isActive () {
      return this.$store.state.global.sound
    }
  },

  mounted () {
    this.randomBars()
  },

  methods: {
    randomBars () {
      if (this.isActive) {
        this.bars.forEach((bar, index) => {
          this.bars[index] = randomRange(.2, 1)
        })
        this.duration = randomRange(.5, 1)
        setTimeout(this.randomBars, this.duration * 1000)
      }
      else {
        this.bars.forEach((bar, index) => {
          this.bars[index] = .2
        })
        this.duration = .5
      }
    },
    onToggle () {
      this.$store.commit('global/sound', !this.isActive)
    }
  },

  watch: { isActive: 'randomBars' }

}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'

.sound
  height 20px

  &__bar
    transition transform ease-in-out-quad
    transform-origin 50% 50%
    height 100%
    
    &.is-bottom
      transform-origin 50% 100%
</style>