import { api } from '@/store/interfaces/apiCraft'
import { annexes } from '@/assets/data'
// import Vue from 'vue'

let totalQuestions = 0

export default {
  namespaced: true,

  state: {
    homepage: {},
    endpage: {},
    config: {},
    annexes: annexes,
    loaded: false
  },

  getters: {
    homepage: state =>{
      return state.homepage
    },
    endpage: state =>{
      return state.endpage
    },
    annexes: state =>{
      return state.annexes
    },
    annexe: state => name =>{
      return state.annexes.find(annexe => annexe.name === name)
    },
    config: state =>{
      return state.config
    },
    tutorials: state =>{
      return state.config.tutorials
    },
    loaded: state =>{
      return state.loaded
    },
    sounds: state =>{
      return {
        background: state.config?.backgroundSound[0]?.url,
        cta: state.config?.CTASound[0]?.url,
        true: state.config?.trueSound[0]?.url,
        false: state.config?.falseSound[0]?.url,
        indice: '/sounds/indice.mp3',
        end: '/sounds/end.mp3',
        qrCode: '/sounds/qrCode.mp3'
      }
    },
  },

  actions: {
    load ({ commit, state, dispatch }) { 
      const promise = Promise.all([dispatch('homepage'), dispatch('endpage'), dispatch('config')])

      promise.then(() => {
        commit('loaded', true)
      })

      return promise
    },

    homepage ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getHomepage()
        cache.then(res => {
          if (res !== null) {
            commit('homepage', res)
            resolve()
          }
        })

        request.then(res => {
          commit('homepage', res)
          resolve()
        })
      })
    },

    endpage ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getEndpage()
        cache.then(res => {
          if (res !== null) {
            commit('endpage', res)
            resolve()
          }
        })

        request.then(res => {
          commit('endpage', res)
          resolve()
        })
      })
    },

    annexes ({ commit, state }) {
      return new Promise(async resolve => {
        for (const annexe of annexes) {
          if (annexe.active) {
            const { request, cache } = api.getPage(annexe)

            request.then(res => {
              commit('annexes', {...annexe, ...res})
            })
            await request
          }
        }
        resolve()
      })
    },

    config ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getConfig()
        cache.then(res => {
          if (res !== null) {
            commit('config', res)
            resolve()
          }
        })

        request.then(res => {
          commit('config', res)
          resolve()
        })
      })
    }
  },

  mutations: {
    homepage (state, payload) {
      state.homepage = payload
    },
    endpage (state, payload) {
      state.endpage = payload
    },
    annexes (state, payload) {
      state.annexes.push(payload)
    },
    config (state, payload) {
      state.config = payload
    },
    loaded (state, payload) {
      state.loaded = payload
    }
  }
}