import * as storage from 'localforage'

storage.config({
  driver: [storage.LOCALSTORAGE, storage.INDEXEDDB, storage.WEBSQL],
  name: 'Bright',
  storeName: 'BrightApp'
})

const cookiePrefix = 'bright_'

const cookie = {
  setItem (name, value, days = 182) {
    let expires = ''

    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + (days*24*60*60*1000))
      expires = 'expires=' + date.toUTCString()
    }

    document.cookie = `${cookiePrefix + name}=${value || ``}; ${expires}; path=/`
  },

  getItem (name) {
    let nameEQ = cookiePrefix + name + "="
    var ca = document.cookie.split(';')
    for (let c of ca) {
      while (c.charAt(0)==' ') c = c.substring(1,c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length)
    }
    return null
  },

  removeItem (name) {
    document.cookie = cookiePrefix + name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}

// localforage.setItem('key', 'value', callback)
// localforage.getItem('key').then()

export { storage, cookie }
