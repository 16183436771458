import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js'
import { Vector3 } from 'three'

const vector3 = new Vector3()

// :style="{transform: `translate3d( ${transformation.x}%, ${transformation.y}%, ${transformation.z}px)`}"

export default {
  props: {
    lookAt: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
          z: 0
        }
      }
    },
    position: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
          z: 0
        }
      }
    },
    transformation: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
          z: 0
        }
      }
    },
    rotation: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
          z: 0
        }
      }
    },
    localRotation: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
          z: 0
        }
      }
    }
  },

  mounted () {
    this.object = new CSS3DObject(this.$el)
    if (!this.$device.isMobile) {
      this.object.scale.set(.1, .1, .1)
    }

    this.$dom3d.addObject(this.object)

    this._placeObject()
    this._rotateObject()
    this._localRotateObject()
  },

  methods: {
    _placeObject () {
      this.object.position.x = this.position.x
      this.object.position.y = this.position.y
      this.object.position.z = this.position.z

      this._lookAt()
    },
    getMatrice () {
      const size = this.$el.getBoundingClientRect()
      return {
        width: size.width,
        height: size.height,
        ratio:  size.width / this.$el.offsetWidth
      }
    },
    _rotateObject () {
      if (this.rotation.x || this.rotation.y || this.rotation.z) {
        this.object.rotation.x = this.rotation.x
        this.object.rotation.y = this.rotation.y
        this.object.rotation.z = this.rotation.z
      }
    },
    _localRotateObject () {
      if (this.localRotation.x || this.localRotation.y || this.localRotation.z) {
        this.object.rotateX(this.localRotation.x)
        this.object.rotateY(this.localRotation.y)
        this.object.rotateZ(this.localRotation.z)
      }
    },
    _lookAt () {
      if (this.lookAt.x || this.lookAt.y || this.lookAt.z) {
        this.object.lookAt(
          this.lookAt.x,
          this.lookAt.y,
          this.lookAt.z
        )
        this._localRotateObject()
      }
    }
  },

  beforeUnmount () {
    this.$dom3d.removeObject(this.object)
  },

  watch: {
    lookAt: {
       handler() {
        if (this.object) {
          this._lookAt()
        }
       },
       deep: true
    },
    position: {
       handler() {
        if (this.object) {
          this._placeObject()
        }
       },
       deep: true
    },
    rotation: {
       handler(val){
        if (this.object) {
          this._rotateObject()
        }
       },
       deep: true
    },
    localRotation: {
       handler(val){
        if (this.object) {
          this._localRotateObject()
        }
       },
       deep: true
    },
  }
}
