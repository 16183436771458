<template>
  <img class="pano-preloader__img" v-for="image in panos" :src="image.url">
</template>

<script>
export default {
  computed: {
    panos () {
      const entries = this.$store.getters['data/views']
      const images = []
      entries.forEach(entry => {
        entry.image.forEach(img => images.push(img))
      })

      return images
    }
  }
}
</script>

<style lang="stylus">
.pano-preloader__img
  display none
  position absolute
  visibility hidden
  // top -10000px
  // left -10000px
</style>