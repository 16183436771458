const messages = {
  tuto: {
    title1: 'Your objective',
    title2: 'At each stage'
  },
  keypoint: {
    more: 'Find out more'
  },
  question: {
    number: 'Question n°',
    question: 'Question',
    win: 'Congrats !',
    lose: 'Almost !',
    more: 'Learn more',
  },
  end: {
    well: 'Congratulations',
    title: 'You made it to the end !',
    text1: 'To take part in the lucky prize draw and a chance to win a Dacia adventure,',
    text2: 'sign up and fill out the form.',
    conditions: 'I accept <a href="/reglement__fr.pdf" target="_blank">game terms and conditions</a>',
    text3: 'The game is open only to adults currently residing in metropolitan France.',
  },
  share: 'Stay connected with Dacia',
  step: 'Stage',
  help: {
    1: 'I can’t find the Dacia emblem',
    2: 'Use a hint'
  },
  pages: {
    personal: 'Personal data',
    cookies: 'Cookies',
    about: 'Disclaimer',
    legals1: 'The website ‘daciaaventure.dacia.fr’ is published by: Renault s.a.s, simplified joint-stock company with a capital of €533,941,113, whose registered office is located at 122-122 bis avenue du Général',
    legals2: 'Leclerc – 92100 Boulogne Billancourt cedex, registered with the R.C.S. of Nanterre under number 780 129 987 (hereinafter "RENAULT"),',
    legals3: 'Director of publication: Justine Kohr',
    legals4: 'daciaaventure.dacia.fr host: Moma Events',
  },
  tutos: {
    title1: 'Your objective',
    title2: 'At each stage',
    subTitle: 'There are 6 QR codes hidden around the stand.',
    obj: 'Find at least 5 QR codes to take part in the draw and win an adventure.'
  },
  legals: 'The game is open only to adults currently residing in metropolitan France.'
}

export default messages
