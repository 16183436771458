<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{
      'is-full': $route.meta.isFull,
      'is-mobile': isMobile
    }"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
    :style="{
      '--viewport-height': $device.height + 'px',
      '--header-height': headerHeight + 'px',
      '--footer-height': footerHeight + 'px'
    }"
  >
    <symbols-svg />
    <div ref="containerWebGL" class="u-wrapper-panel"/>

    <!-- <bottom-links @page="onPage" /> -->
    <app-header v-if="!$device.isDesktop" :height="headerHeight" @menu="isMenu = !isMenu" />
    <app-footer v-else @page="onPage" :height="footerHeight" />

    <router-view v-slot="{ Component }">
      <transition name="t-fader--delayed">
        <component v-if="loaded" :is="Component" />
      </transition>
    </router-view>

    <transition name="t-translate--down">
      <menu-mobile @page="onPage" v-if="isMenu" />
    </transition>

    <transition name="t-fader">
      <base-page :name="currentPage" v-if="currentPage !== ''" @close="currentPage = ''" />
    </transition>

    <!-- <cookie /> -->

    <div ref="containerCSS3D" class="u-wrapper-panel u-overflow-hidden u-pointer-none"/>

    <!-- <transition name="transition" :duration="isTransition ? duration * .15 : duration * .1">
      <transition-screen v-if="isTransition" :duration="isTransition ? duration * .15 : duration * .1" />
    </transition> -->

    <transition name="custom" duration="2000">
      <app-loader v-if="!loaded" />
    </transition>

    <pano-preloader />
  </div>
</template>

<script>
import { LoaderManager, pointer } from 'shimmer'

import App from './App'

import { webGL } from '@/webGL/WebGL'

import AppHeader from '@/components/common/AppHeader'
import AppFooter from '@/components/common/AppFooter'
import MenuMobile from '@/components/common/MenuMobile'
import AppLoader from '@/components/common/Loader'
import TransitionScreen from '@/components/common/TransitionScreen'
import Debugger from '@/components/Debugger'

import QrCodeReader from '@/components/QrCodeReader'

// import Cookie from '@/components/common/cookie'

import BasePage from '@/views/BasePage'
import SymbolsSvg from '@/components/SymbolsSvg'
import PanoPreloader from '@/components/PanoPreloader'

import { Audio } from '@/utils/Audio'

import { levelFPS } from '@/utils/LevelFPS'

import { textureLib } from '@/webGL/TextureLib'

import { cookie } from '@/utils/storage'

import { nbrPass, nbrPassInsitu, setup } from '@/assets/data'

import {
  AmbientLight,
  SphereBufferGeometry,
  MeshNormalMaterial,
  Mesh
} from 'three'

export default {
  name: 'App',

  mixins: [App],

  components: {
    SymbolsSvg,
    AppHeader,
    AppFooter,
    MenuMobile,
    // Cookie,
    AppLoader,
    BasePage,
    Debugger,
    TransitionScreen,
    QrCodeReader,
    PanoPreloader
  },

  data () {
    return {
      headerHeight: 70,
      footerHeight: 55,
      isAbout: false,
      isNavigation: false,
      isMenu: false,
      isTransition: false,
      currentPage: '',
      isFullScreen: false,
      duration: setup.duration * 1000,
      loaded: false,
      isMobile: false
    }
  },

  computed: {
    config () {
      return this.$store.getters['pages/config']
    },
    isFinished () {
      const pass = this.$store.state.data.isInSitu ? nbrPassInsitu : nbrPass
      return this.$store.getters['data/questionAnswered'] >= this.$store.getters['data/totalQuestions'] - pass
    }
  },

  async created () {
    this.pointerevent = null

    const promises = []

    await this.$store.dispatch('data/seenItems')
    promises.push(this.$store.dispatch('pages/load'))
    promises.push(this.$store.dispatch('data/keypoints').then(() => {
      promises.push(this.$store.dispatch('data/views'))
      this.$store.dispatch('data/currentQuestionIndexNext')
    }))

    Promise.all(promises).then(() => {
      this.onInit()
      this.loaded = true
    })
  },

  mounted () {
    this.onResize()

    this.isMobile = !!this.$device.isMobile
    webGL.camera.fov = this.$device.isMobile ? 75 : 60

    webGL.camera.position.set(0, 0, 0)
    webGL.camera.look.set(0, 0, 1)
  },

  methods: {
    onInit () {
      // if (this.$store.getters['pages/sounds'].background) {
      //   this.audio = new Audio()
      //   this.audio.player.loop = true
      //   this.audio.player.volume = .3
      //   this.audio.src = this.$store.getters['pages/sounds'].background
      //   this.$hub.on('audio:background', this.audio.play)
      // }
    },

    onResize () {
      this.headerHeight = !this.$device.isDesktop ? this.headerHeight : 0
      this.footerHeight = this.$device.isDesktop ? this.footerHeight : 0

      this._onResize()
    },

    onPage (name) {
      this.currentPage = name
    },

    fullscreen () {
      if (this.isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
        else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
        this.isFullScreen = false
      }
      else {
        if (document.body.requestFullscreen) {
          document.body.requestFullscreen()
        }
        else if (document.body.webkitRequestFullscreen) {
          document.body.webkitRequestFullscreen()
        }
        this.isFullScreen = true
      }
    }
  },

  watch: {
    $route (to, from) {
      if (!from.name) {
        if (to.name.includes('InSitu')) {
          this.$store.commit('data/isInSitu')
        }
      }
    },
    isFinished () {
      if (this.isFinished) {
        if (this.$route.meta.isInSitu) {
          this.$router.push(this.$local('InSitu/End'))
        }
        else {
          this.$router.push(this.$local('End'))
        }
      }
    }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .2px
    bottom .2px
    left 0
    right 0
    overflow hidden
    min-height auto

.testlinks
  position absolute
  bottom 0
  left 0
  width 100%
</style>