import { clock, Countdown } from 'shimmer'

import { emitter } from '@/utils/emitter'
import { renderLevels, autoRenderLeveling } from '@/assets/data'

import { cookie } from '@/utils/storage'

// nbr of frames for the average values
const historyLength = 60*3
// duration between each quality change
const coolDown = 4000

export class LevelFPS {
  constructor (level = renderLevels.length - 1) {
    this._level = level

    // if autoRenderLeveling is authorized
    // and user as already seen website degraded
    // we just step down to first level of degradation
    const cookieResult = cookie.getItem('renderlevel')
    if (cookieResult !== null && cookieResult < renderLevels.length - 1 && autoRenderLeveling) {
      this._level = this._level - 1
    }

    this.update = this.update.bind(this)
    this.onCooled = this.onCooled.bind(this)

    this.history = new Array(historyLength)
    this.isCooling = true
    this.countdown = new Countdown(coolDown)
    this.average = 0
    this._active = false

    clock.on(this.update)
  }

  update ({ delta }) {
    this.history.pop()
    this.history.unshift(delta)
    // 16 should be the average
    // 25 is 40fps
    // 33 is 30fps
    this.average = this.history.reduce((acc, curr) => acc + curr, 0) / this.history.length

    if ( this.isCooling || !this.active ) { return }

    if (this.average > 30 && this.level > 0) {
      this.level = this.level - 1
    }
  }

  onCooled () {
    this.isCooling = false
  }

  set active (active) {
    this._active = active && autoRenderLeveling

    if (this._active) {
      this.countdown.start(this.onCooled)
    }
  }

  get active () {
    return this._active
  }

  set level (level) {
    this.isCooling = true
    this._level = level
    cookie.setItem('renderlevel', this._level)

    this.countdown.start(this.onCooled)
    emitter.emit('level:change', this._level)
  }

  get level () {
    return this._level
  }

  get levelProperty () {
    return renderLevels[this._level]
  }
}

export const levelFPS = new LevelFPS