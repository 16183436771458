<template>
  <div class="u-wrapper-vpanel u-flex u-column">
    <div class="homepage__header u-z-back u-w3of12 u-w12of12--md u-pad-sm u-bg-white u-uppercase u-relative">
      <div v-if="$device.isDesktop" class="u-marg-b-xs">
        <div class="u-w4of12 u-box-16by9">
          <div class="u-wrapper-panel">
            <svg class="u-fill-black u-full-height u-full-width u-fit-contain">
              <use xlink:href="#dacia"/>
            </svg>
          </div>
        </div>
      </div>
      <h2 class="t-h1 u-pad-b-xxs">
        {{ data.title }}
      </h2>
      <div class="homepage__legals">
        {{ $t('legals') }}
      </div>
      <div class="u-right">
        <app-button :to="$local($route.meta.isInSitu ? 'InSitu/Tuto' : 'Tuto')" :red="true" :icon="true" class="homepage__button u-z-back">
          <svg class="u-fill-white homepage__button__svg" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#arrow-right"/>
          </svg>
        </app-button>
      </div>
    </div>
    <div class="homepage__img">
      <app-images :width="1920" :height="1080" class="u-fit-cover" :images="data.image" />
    </div>
  </div>
</template>

<script>
import PopinTuto from '@/components/PopinTuto'
import IndiceTuto from '@/components/IndiceTuto'
import { webGL } from '@/webGL/WebGL'

import { Vector3 } from 'three'

export default {
  components: {
    PopinTuto,
    IndiceTuto
  },

  data () {
    return {
      transition: true,
      tutoIndex: -1
    }
  },

  computed: {
    data () {
      return this.$store.getters['pages/homepage']
    },
    tutorials () {
      return this.$store.getters['pages/tutorials']
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/mixins/viewport-sizer'

.homepage
  &__legals
    viewportSizer('font-size', 10, 400, null, 11px)
    font-family dacia

  &__img
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    +mq($until: 'desktop')
      position relative
      top auto
      left auto
      width auto
      height auto
      flex-grow: 1
  
  &__button
    position relative
    
    +mq($until: 'desktop')
      position absolute
      top: calc(100% - 20px)
      right: $spacings.sm
    
    &__svg
      height 20px
      width 25px

</style>