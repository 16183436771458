<template>
  <div>
    <component
      :is="to.name ? 'router-link' : 'div'"
      :to="to"
      class="keypoint u-cursor-pointer"
      draggable="false"
      :class="{'is-active': isVisible, 'is-complete': isComplete}"
      @click.stop.prevent="$emit('click')"
    >
      <div class="keypoint__item u-box-1by1">
        <div class="keypoint__item__main u-wrapper-panel" />
        <div class="keypoint__item__pulse" :class="{'is-active': !isSelected && !isComplete}"></div>
        <div class="keypoint__item__heart"></div>
      </div>
    </component>
  </div>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'
import { webGL } from '@/webGL/WebGL'

export default {
  mixins: [Object2d],

  created () {
    this._camera = webGL.camera
  },

  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    isComplete: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

width = 25px
halfWidth = width * 0.5

.keypoint  
  opacity 0

  &.is-active
    opacity 1

  &__title
    position absolute
    top 0
    left 0
    height 100%
    max-width 150px
    border-radius 0 15px 15px 0
    background: $colors.orange
    color: $colors.red
    padding: 0 $spacings.sm 0 halfWidth

  &__item
    top 0
    width width
    border-radius 50%
    left -1 * halfWidth
    // top -1 * halfWidth
    transition opacity .3s linear
    
    &__main
      border-radius 50%
      background-color $colors.black
      opacity .3

    &__heart
      position absolute
      background: $colors.orange
      height 40%
      width 40%
      top 30%
      left 30%
      border-radius 50%

    &__pulse
      position absolute
      border solid 3px $colors.orange
      height 200%
      width 200%
      top -50%
      left -50%
      border-radius 50%
      opacity 0

      &.is-active
        opacity 1
        transform: translateZ(0) scale(.5)
        // animation 2s ease-in-out-quad infinite pulse

  &.is-complete &__item
    &__main
      background-color:  $colors.orange
    &__heart
      background-color: $colors.white


@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0
</style>