<template>
  <div class="question u-full-width u-full-height u-relative u-overflow-y-scroll u-left u-invisible-scroll u-z-back">
    <transition name="t-translate--down--delayed">
      <!-- QUESTION HEADER -->
      <div v-if="!isAnswer" class="u-bg-grey--md u-black u-pad-sm u-pad-t-sm--md">
        <div class="t-h3">
          <!-- {{ $t('question.number') }} {{ data.globalIndex + 1 }} -->
          {{ $t('question.question') }}
        </div>
        <div class="t-text u-marg-t-sm u-marg-t-xxs">
          {{ data.title }}
        </div>
      </div>
    </transition>

    <transition name="t-fader--delayed">
      <!-- ANSWERS -->
      <div v-if="!isAnswer && isReady" class="u-pad-sm">
        <app-answer
          class="u-full-width u-marg-t-sm"
          :label="answer.label"
          :isTrue="answer.value"
          :name="data.title"
          v-for="answer in data.answers"
          :isSelecting="(!isValidate || isCategories) && selectedIndex(answer) >= 0"
          :isSelected="isValidate && !isCategories"
          @select="onSelect(answer)"
          v-show="!isAnswer || answer.value"
        />
      </div>
    </transition>

    <!-- CTA VALIDATE -->
    <transition name="t-fader--delayed">
      <div v-if="selectedAnswers.length && !isAnswer" class="question__cta u-right">
        <app-button @click="onValidate" :icon="true" :red="true" class="u-pointer-all">
          <svg class="u-fill-white question__cta__svg" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#arrow-right"/>
          </svg>
        </app-button>
      </div>
    </transition>

    <!-- ANSWER TEXT -->
    <transition name="t-translate--down--delayed">
      <div v-if="isAnswer" class="u-wrapper-panel">
        <div class="question__answer-info u-relative u-white" :class="isRight ? 'u-bg-green' : 'u-bg-red'">
          <div class="t-h2 u-marg-b-sm" v-html="isRight ? $t('question.win') : $t('question.lose')" />
          <!-- <div class="t-h4 u-uppercase u-marg-b-xxs">
            {{ $t('question.more') }}
          </div> -->
          <div class="t-text">
            {{ isRight || !data.textLose ? data.textWin : data.textLose }}
          </div>
        </div>
      </div>
    </transition>
    <transition name="t-fader">
      <div v-if="isAnswer && isReady" class="question__cta u-right">
        <app-button
          @click="onNext"
          :icon="true"
          :red="$device.isDesktop ? false : true"
          :white="$device.isDesktop ? true : false"
          class="u-pointer-all"
        >
          <svg class="question__cta__svg" :class="$device.isDesktop ? 'u-fill-red' : 'u-fill-white'" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#arrow-right"/>
          </svg>
        </app-button>
      </div>
    </transition>
  </div>
</template>

<script>
import AppAnswer from '@/components/common/AppAnswer'
import AppButton from '@/components/common/AppButton'
import { audio } from '@/utils/Audio'

export default {
  components: {
    AppAnswer,
    AppButton
  },

  computed: {
    goodAnswers () {
      return this.data.answers.filter(answer => !!answer.value)
    },
    isCategories () {
      return !!this.data.answers.filter(answer => !!answer.categories.length).length
    },
    isQCM () {
      return this.goodAnswers.length > 1
    }
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    index: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      selectedAnswers: [],
      isValidate: false,
      isAnswer: false,
      isRight: false,
      isReady: false
    }
  },

  mounted () {
    setTimeout(() => {
      this.isReady = true
    }, 2000)
  },

  methods: {
    onSelect (answer) {
      const index = this.selectedIndex(answer)

      if (this.isQCM) {
        if (index < 0) {
          this.selectedAnswers.push(answer)
        } else {
          this.selectedAnswers.splice(index, 1)
        }
      }
      else {
        if (index < 0) {
          this.selectedAnswers.splice(0, this.selectedAnswers.length)
          this.selectedAnswers.push(answer)
        }
      }
    },

    onValidate () {
      if (this.isValidate) { return }

      this.isValidate = true

      this.isRight = arrayEquals(this.goodAnswers, this.selectedAnswers)
      this.$emit('answer', {right: this.isRight, answers: this.selectedAnswers })

      if (!this.isCategories) {
        if (this.isRight) {
          this.$ga.send('gameplay', 'answerTrue', this.data.slug)

          // if (this.$store.getters['pages/sounds'].true) {
          //   audio.src = this.$store.getters['pages/sounds'].true
          //   audio.play()
          // }
        }
        else {
          this.$ga.send('gameplay', 'answerFalse', this.data.slug)

          // if (this.$store.getters['pages/sounds'].false) {
          //   audio.src = this.$store.getters['pages/sounds'].false
          //   audio.play()
          // }
        }
        setTimeout(this.onAnswer, 1500)
      }
      else {
        this.$emit('next')
      }
    },

    onAnswer () {
      this.isAnswer = true
      this.$hub.emit('answer')
    },

    selectedIndex (answer) {
      return this.selectedAnswers.findIndex(selected => selected.index === answer.index)
    },

    onNext () {
      this.$ga.send('gameplay', 'continuer')
      // to prevent next button to be in transition translate of the component
      this.isReady = false

      setTimeout(() => {
        this.$emit('next')
      }, 1000)
    }
  }
}

function arrayEquals(a, b) {
  a.sort((c, d) => { return c.index < d.index ? -1 : 1 })
  b.sort((c, d) => { return c.index < d.index ? -1 : 1 })
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val.index === b[index].index);
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'


.question
  max-height 100%
  
  &__cta
    position absolute
    bottom: $spacings.sm
    left: $spacings.sm
    right: $spacings.sm
    
    &__svg
      height 27px
      width 32px

  &__answer-info
    padding: $spacings.md $spacings.sm $spacings.sm $spacings.sm

    +mq($from: 'desktop')
      height 100%

</style>