<template>
  <div style="position: fixed; width: 0; height: 0; overflow: hidden; top: -99px">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="circle-small" viewBox="0 0 78 78">
          <title>circle small</title>
          <circle fill="transparent" cx="39" cy="39" r="36" stroke-width="3"/>
        </symbol>
        <symbol id="dacia" viewBox="0 0 102 28">
          <title>Dacia</title>
          <path d="M0 0V9.30726H35.0726V13.9999V18.6927H0V28H38.7249C39.6132 28 40.1987 27.758 40.8041 27.1602L50.9999 17.42L61.1959 27.1602C61.8013 27.758 62.3868 28 63.2751 28H102V18.6927H66.9271V13.9999V9.30726H102V0H63.2751C62.3868 0 61.8013 0.24201 61.1959 0.839837L50.9999 10.58L40.8041 0.839837C40.1987 0.24201 39.6132 0 38.7249 0H0Z"/>
        </symbol>
        <symbol id="menu" viewBox="0 0 42 24">
          <title>Menu</title>
          <path d="M0.242188 0H41.713V4H0.242188V0ZM0.242188 10H41.713V14H0.242188V10ZM0.242188 20H41.713V24H0.242188V20Z"/>
        </symbol>
        <symbol id="close" viewBox="0 0 20 14">
          <title>Close</title>
          <path d="M4.81081 13.5135L9.58559 8.77478L14.6126 13.5135H20L12.5766 6.73874L19.6577 0H14.8468L10.2342 4.55856L5.40541 0H0.018018L7.24324 6.61261L0 13.5135H4.81081Z"/>
        </symbol>
        <symbol id="arrow-down" viewBox="0 0 25 16">
          <title>Arrow down</title>
          <path d="M12.2528 9.09091C15.3159 7.09091 21.9136 2.18182 24.9768 0C24.9768 0.181818 24.9768 0.181818 24.9768 0.363636C24.9768 2.54545 24.9768 4.72727 24.9768 6.90909C24.9768 7.09091 24.9768 7.27273 24.7411 7.45455C20.7354 10.1818 16.7297 12.9091 12.724 15.8182L12.4884 16C12.0171 15.6364 11.5459 15.2727 11.0746 14.9091C7.54015 12.3636 3.77008 10 0.23563 7.45455C0.23563 7.27273 0 7.09091 0 7.09091C0 4.90909 0 2.54545 0 0.363636C0 0.363636 0 0.181818 0 0C3.06319 2 9.42519 6.72727 12.2528 9.09091Z"/>
        </symbol>
        <symbol id="arrow-right" viewBox="0 0 29 24">
          <title>Arrow Right</title>
          <path d="M9.41892 23.2C11.9189 20.4 14.3189 17.7 16.8189 14.9C11.3189 14.9 5.91892 14.9 0.518921 14.9C0.518921 12.7 0.518921 10.6 0.518921 8.4C5.91892 8.4 11.3189 8.4 16.8189 8.4C14.3189 5.6 11.8189 2.8 9.31892 0C9.61892 0 9.81892 0 9.91892 0C12.6189 0 15.2189 0 17.9189 0C18.1189 0 18.3189 0.1 18.5189 0.2C21.8189 3.9 25.2189 7.6 28.5189 11.4C28.6189 11.5 28.6189 11.5 28.7189 11.6C28.3189 12.1 27.9189 12.5 27.5189 12.9C24.5189 16.3 21.5189 19.6 18.5189 23C18.4189 23.1 18.2189 23.2 18.0189 23.2C15.3189 23.2 12.6189 23.2 9.91892 23.2C9.71892 23.3 9.61892 23.3 9.41892 23.2Z"/>
        </symbol>
        <symbol id="arrow-left" viewBox="0 0 23 22">
          <title>Arrow Left</title>
          <path d="M23 9.5L23 12.5L5 12.5L13 18.5L11.5 21.5L-8.04673e-07 12L-9.53694e-08 10L11.5 0.499999L13 3.5L5 9.5L23 9.5Z" fill="#646B52"/>
        </symbol>
        <symbol id="i" viewBox="0 0 10 35">
          <title>i</title>
          <rect x="0.0429688" y="0.323975" width="9.30802" height="7.44642"/>
          <rect x="0.0429688" y="10.7488" width="9.30802" height="23.8286"/>
        </symbol>
        <symbol id="?" viewBox="0 0 31 33">
          <title>?</title>
          <path d="M16.4739 22.0402L17.0966 19.757L24.9839 17.5153C28.9276 16.436 30.339 14.568 30.339 10.3337V7.88451C30.339 2.94456 26.4784 0.619873 15.0625 0.619873C10.1641 0.619873 5.05806 1.15953 0.408691 2.23885L1.57103 8.8808C5.59772 8.05056 10.2056 7.46939 14.5644 7.46939C20.3345 7.46939 23.2819 8.13358 23.2819 9.50349V10.6658C23.2819 11.7036 22.7423 12.1603 21.2478 12.5754L10.5792 15.4812L11.4094 22.0402H16.4739ZM9.83196 32.1692H17.9684V24.6139H9.83196V32.1692Z"/>
        </symbol>
        <symbol id="youtube" viewBox="0 0 32 24">
          <title>youtube</title>
          <path d="M16.0198 23.2003H15.9878C15.8887 23.2003 5.9721 23.1811 3.47017 22.5017C2.79415 22.3196 2.17783 21.9631 1.68291 21.4679C1.18799 20.9727 0.831839 20.3562 0.650105 19.68C0.200212 17.1419 -0.0170439 14.568 0.00104265 11.9904C-0.0105723 9.40858 0.212562 6.83103 0.667691 4.28959C0.854559 3.61235 1.21236 2.99451 1.70675 2.49535C2.20114 1.9962 2.81553 1.6325 3.49095 1.43915C5.92414 0.799683 15.5674 0.799683 15.9766 0.799683H16.0102C16.1109 0.799683 26.0531 0.818866 28.5294 1.4983C29.9027 1.8708 30.9754 2.94351 31.3463 4.31677C31.8099 6.86506 32.0289 9.45012 31.9969 12.04C32.0081 14.6187 31.7845 17.1931 31.3287 19.7312C31.1446 20.4066 30.787 21.022 30.2915 21.5165C29.7959 22.0109 29.1796 22.3671 28.5038 22.5497C26.0739 23.1939 16.429 23.2003 16.0198 23.2003ZM12.8224 7.20238L12.8144 16.7944L21.1467 11.9984L12.8224 7.20238Z"/>
        </symbol>
        <symbol id="instagram" viewBox="0 0 32 32">
          <title>instagram</title>
          <path d="M15.9957 10.6644C13.0578 10.6644 10.6602 13.062 10.6602 15.9999C10.6602 18.9379 13.0578 21.3354 15.9957 21.3354C18.9336 21.3354 21.3312 18.9379 21.3312 15.9999C21.3312 13.062 18.9336 10.6644 15.9957 10.6644ZM31.9982 15.9999C31.9982 13.7905 32.0182 11.601 31.8941 9.39559C31.7701 6.83391 31.1857 4.56041 29.3125 2.68718C27.4352 0.80995 25.1657 0.229569 22.604 0.105488C20.3946 -0.0185938 18.2052 0.00141944 15.9997 0.00141944C13.7902 0.00141944 11.6008 -0.0185938 9.39536 0.105488C6.83368 0.229569 4.56019 0.813953 2.68696 2.68718C0.809722 4.56442 0.22934 6.83391 0.105259 9.39559C-0.0188226 11.605 0.00119056 13.7945 0.00119056 15.9999C0.00119056 18.2054 -0.0188226 20.3988 0.105259 22.6043C0.22934 25.166 0.813725 27.4394 2.68696 29.3127C4.56419 31.1899 6.83368 31.7703 9.39536 31.8944C11.6048 32.0185 13.7943 31.9984 15.9997 31.9984C18.2092 31.9984 20.3986 32.0185 22.604 31.8944C25.1657 31.7703 27.4392 31.1859 29.3125 29.3127C31.1897 27.4354 31.7701 25.166 31.8941 22.6043C32.0222 20.3988 31.9982 18.2094 31.9982 15.9999ZM15.9957 24.2093C11.4527 24.2093 7.78631 20.5429 7.78631 15.9999C7.78631 11.4569 11.4527 7.79054 15.9957 7.79054C20.5387 7.79054 24.2051 11.4569 24.2051 15.9999C24.2051 20.5429 20.5387 24.2093 15.9957 24.2093ZM24.5413 9.37158C23.4806 9.37158 22.6241 8.51501 22.6241 7.45432C22.6241 6.39362 23.4806 5.53706 24.5413 5.53706C25.602 5.53706 26.4586 6.39362 26.4586 7.45432C26.4589 7.70618 26.4095 7.95564 26.3133 8.18839C26.217 8.42115 26.0758 8.63263 25.8977 8.81073C25.7196 8.98883 25.5082 9.13004 25.2754 9.22628C25.0426 9.32252 24.7932 9.37189 24.5413 9.37158Z" />
        </symbol>
        <symbol id="facebook" viewBox="0 0 32 32">
          <title>facebook</title>
          <path d="M5.843 0C2.60623 0 0 2.60623 0 5.843V26.157C0 29.3937 2.60623 32 5.843 32H16.853V19.49H13.545V14.986H16.853V11.138C16.853 8.1148 18.8075 5.339 23.31 5.339C25.1329 5.339 26.481 5.514 26.481 5.514L26.375 9.72001C26.375 9.72001 25.0001 9.70702 23.5 9.70702C21.8763 9.70702 21.616 10.4551 21.616 11.697V14.986H26.5039L26.291 19.49H21.616V32H26.1569C29.3937 32 31.9999 29.3938 31.9999 26.157V5.84303C31.9999 2.60627 29.3937 3.19999e-05 26.1569 3.19999e-05H5.84296L5.843 0Z"/>
        </symbol>
        <symbol id="twitter" viewBox="0 0 32 28">
          <title>twitter</title>
          <path d="M32 4.07876C30.8232 4.6002 29.5591 4.95253 28.2301 5.11179C29.6013 4.29127 30.6272 2.99988 31.1164 1.47856C29.828 2.24381 28.418 2.78246 26.9476 3.07109C25.9588 2.01532 24.6491 1.31554 23.2218 1.08039C21.7945 0.845246 20.3296 1.08789 19.0543 1.77065C17.7791 2.45341 16.7649 3.53809 16.1693 4.85628C15.5737 6.17448 15.43 7.65244 15.7604 9.0607C13.1499 8.92963 10.5961 8.25112 8.26485 7.0692C5.93354 5.88728 3.87681 4.22837 2.22813 2.20013C1.66441 3.17256 1.34026 4.30002 1.34026 5.50076C1.33963 6.5817 1.60582 7.64609 2.11522 8.59948C2.62461 9.55287 3.36145 10.3658 4.26037 10.9661C3.21786 10.9329 2.19836 10.6512 1.28671 10.1445V10.229C1.2866 11.7451 1.81102 13.2145 2.77098 14.3879C3.73094 15.5613 5.06731 16.3665 6.55333 16.6668C5.58624 16.9285 4.57231 16.9671 3.58813 16.7795C4.0074 18.084 4.82409 19.2247 5.92389 20.042C7.02368 20.8593 8.35151 21.3122 9.72148 21.3373C7.39587 23.1629 4.52376 24.1532 1.56716 24.1489C1.04343 24.149 0.520146 24.1184 0 24.0573C3.00111 25.9869 6.49462 27.0109 10.0625 27.007C22.1404 27.007 28.7431 17.0036 28.7431 8.32785C28.7431 8.04599 28.736 7.76131 28.7233 7.47944C30.0076 6.55067 31.1162 5.40055 31.9972 4.08298L32 4.07876Z"/>
        </symbol>
        <symbol id="linkedin" viewBox="0 0 32 32">
          <title>linkedin</title>
          <path d="M6.2708 0C2.82235 0 0 2.82229 0 6.2708V25.7302C0 29.1787 2.82229 32 6.2708 32H25.7302C29.1787 32 32 29.1787 32 25.7302V6.2708C32 2.82235 29.1787 0 25.7302 0H6.2708ZM7.84801 5.28064C9.50146 5.28064 10.5199 6.36611 10.5514 7.79294C10.5514 9.18827 9.5014 10.3042 7.81602 10.3042H7.785C6.16301 10.3042 5.11465 9.18834 5.11465 7.79294C5.11465 6.36614 6.19474 5.28064 7.84797 5.28064H7.84801ZM22.0968 11.9495C25.2767 11.9495 27.6605 14.0279 27.6605 18.4943V26.8323H22.8279V19.0533C22.8279 17.0985 22.1285 15.7649 20.3796 15.7649C19.0445 15.7649 18.2487 16.6638 17.8993 17.5321C17.7716 17.8428 17.7403 18.2767 17.7403 18.7113V26.8323H12.9077C12.9077 26.8323 12.9711 13.6549 12.9077 12.2905H17.7413V14.3498C18.3835 13.3589 19.5322 11.9494 22.0968 11.9494V11.9495ZM5.3997 12.2915H10.2323V26.8324H5.3997V12.2915V12.2915Z"/>
        </symbol>
      </defs>
    </svg>
    <!--
    CONTAIN
    <svg preserveAspectRatio="xMidYMid meet">
    COVER
    <svg preserveAspectRatio="xMidYMid slice">
      <use xlink:href="#arrow-left"/>
    </svg> -->
  </div>
</template>


