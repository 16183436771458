<template>
  <div class="socials u-row u-middle" :class="'u-' + display">
    <a v-for="(social, index) in socials" :href="social.url" target="_blank" class="socials__item" :class="{'u-marg-l-xs': index !== 0}">
      <svg class="u-full-width u-full-height" :class="'u-fill-' + color" preserveAspectRatio="xMidYMid slice">
        <use :xlink:href="'#' + social.icon"/>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    display: {
      type: String,
      default: 'evenly'
    },
    color: {
      type: String,
      default: 'black'
    }
  },

  data () {
    return {
      socials: [
        {
          name: 'Youtube',
          icon: 'youtube',
          url: 'https://www.youtube.com/user/daciafr'
        },
        {
          name: 'Facebook',
          icon: 'instagram',
          url: 'https://www.instagram.com/dacia_france/'
        },
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://fr-fr.facebook.com/daciafrance/'
        },
        {
          name: 'Twitter',
          icon: 'twitter',
          url: 'https://twitter.com/dacia_fr'
        },
        {
          name: 'Linkedin',
          icon: 'linkedin',
          url: 'https://www.linkedin.com/company/38866/'
        }
      ]
    }
  }
}
  
</script>

<style lang="stylus">
.socials
  &__item
    width 1.5em
    height 1.5em
</style>